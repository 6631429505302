#root {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .cont {
    margin: 25px;
  }
  
  .customtooltipStyleChange {
    color: #006080
  }
  
 
  .customtooltip {
      position: relative;
      display: inline-block;
  }
  
  .customtooltip .customtooltiptext.lighttooltip {
      background-color: #ffffff;
      color: #333333;
      border: 1px solid rgba(0,0,0,.2);
  }
  
  .customtooltip .customtooltiptext.darktooltip {
      background-color: #7e7979;
      color: #ffffff;
  }
  
  .customtooltip .customtooltiptext {
      visibility: hidden;
      position: absolute;
      max-width: 400px;
      min-width: 150px;
      text-align: center;
      padding: 5px;
      border-radius: 6px;
      z-index: 999;
      transition: opacity 0.3s;
      box-shadow: 0 2px 6px rgba(0,0,0,1);
      height: fit-content;
      white-space: normal;
      left: 0 !important;
      top: 45px !important;
  }
  
  .customtooltip:hover .customtooltiptext {
      visibility: visible;
      opacity: 1;
  }
  
  .customtooltip-right {
    top: -5px;
    left: 125%;  
  }
  
  .customtooltip-right::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #555 transparent transparent;
  }
  
  .customtooltip-bottom {
    top: 135%;
    left: 50%;  
    margin-left: -20px;
  }
  
  .customtooltip-bottom::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 11px;
    border-style: solid;
    border-color: transparent transparent #55555530 transparent;
  }
  
  .customtooltip-top {
    bottom: 125%;
    left: 50%;  
    margin-left: -60px;
  }
  
  .customtooltip-top::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #000000 transparent transparent transparent;
  }
  
  .customtooltip-left {
    top: -5px;
    bottom:auto;
    right: 128%;  
  }
  .customtooltip-left::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent transparent #555;
  }
  
  .arrow{
    position: absolute;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    // border-bottom: 10px solid #000;
    z-index: 999;
  }