@import '../../../../../assets/styles/styles.scss';

.status-badge {
    padding: 0.25em 0.5rem;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.019rem;

    @include border-radius(.125rem, .125rem, .125rem, .125rem);

    &.status-pre-campaign {
        background: $mai-blueshade5;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: $mai-blue-shade2;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);
    }

    &.status-on-going {
        background: $mai-greenshade7;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        text-align: center;
        color: $mai-green-shade3;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);

    }

    &.status-post-campaign {
        background: $mai-orange-shade2;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        text-align: center;
        color: $mai-orangeshade2;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);
    }
}

.campaign-list {

    thead {
        th {

            &:last-child {
                justify-content: center;
            }

            .pi {
                font-size: 0.75rem;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(2n+2) {
                background: $mai-aliceblue-shade;
            }

            &.p-datatable-emptymessage {

                td {
                    max-width: 100% !important;
                    justify-content: flex-start !important;
                }
            }
        }

        td {
            &:first-child {
                font-weight: 700;
            }

            &:nth-child(2) {
                // max-width: 19.5% !important;
                font-weight: 400;
                white-space: pre-wrap;
            }

            &:nth-child(3) {
                // max-width: 11.5% !important;
                font-weight: 400;
                white-space: pre-wrap;
            }

            &:last-child {
                justify-content: center !important;
            }

        }
    }

}