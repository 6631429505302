@import '../../../../../assets/styles/styles.scss';

.custprofile-chartindicator {
    ul {
        line-height: 1.13rem;
    }
}

.subtitle {
    font-weight: 400;
    font-size: .88rem;
    line-height: 1.07rem;
    color: $black;
    opacity: 0.4;
}

.matrixitemwrap {
    min-height: 29.07rem !important;
}

.f-20 {
    font-size: 1.25rem !important;
}

.f-18 {
    font-size: 1.13rem !important;
}

.saveasaudiencePopup {

    .modal-dialog {
        max-width: 80%;
    }
}

.matrix-header {
    display: flex;
    align-items: center;

    .matrix-header-inner {
        width: 33%;
        text-align: center;

    }
}

.matrix-content-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .matrix-content-lheading {
        width: 5%;
        height: 13.88rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;

        .matrix-content-innerlheading {
            width: .94rem;
            height: 4.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -.63rem;


            p {
                transform: rotate(-90deg);
            }
        }

        &.rheading {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                transform: rotate(-90deg);
            }
        }
    }

    .matrix-content-box {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .matrix-content-box__inner {
            height: 4.25rem;
            width: 31%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $white;
            margin-bottom: .57rem;
            cursor: pointer;
            @include border-radius();

            &:nth-child(7) {
                margin-bottom: 0;
            }

            &:nth-child(8) {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            p {
                font-size: .88rem;
            }

            span {
                background: rgb(18 64 36 / 80%);
                line-height: .88rem;
                font-size: .69rem;
                min-width: 1.57rem;
                min-height: 1.13rem;
                text-align: center;
                @include border-radius(.25rem, .25rem, .25rem, .25rem);
                padding: .07rem .19rem;
            }

            &.matrix1-clr {
                background: $mai-sea-green;
            }

            &.matrix2-clr {
                background: $mai-darklime-green;
            }

            &.matrix3-clr {
                background: $mai-greenshade5;
            }
            &.matrix4-clr {
                background: $mai-darklime-green;
            }

            &.matrix5-clr {
                background: $mai-greenshade5;
            }

            &.matrix6-clr {
                background: $mai-orangeshade;
            }
            &.matrix7-clr {
                background: $mai-greenshade5;
            }

            &.matrix8-clr {
                background: $mai-orangeshade;
            }

            &.matrix9-clr {
                background: $mai-redshade3;
            }

            &.default-matrix {
                opacity: 1;
            }

            &.selected-matrix {
                opacity: 1;
            }

            &.unselected-matrix {
                opacity: 0.5;
            }
        }
    }
}
.whiteboxheight-clvdetails-customer{
    height: 32.5rem;
}
.clv-geography-wrap{
    min-height: 470px !important;
}