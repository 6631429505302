@import "../../../../../../assets/styles/styles.scss";

.audiencecreate__form-wrapper {
    .audiencecreate__form {
        .subsegment-field {
            margin-top: .94rem;

            ul {
                &.p-autocomplete-multiple-container {
                    li {
                        &.p-autocomplete-input-token {
                            padding: 0;
                            margin: 0;

                            input {
                                padding: 0.75rem 0.75rem;
                            }
                        }
                    }
                }

            }
        }
    }
}