@import '../../../../../../assets/styles/styles.scss';

.recomdgenaraldetailswrapper {

    .audiencecreate__form {

        .mai-textarea {
            min-height: 5rem;
        }

        .choosedata-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .createsegment-or{
            margin-top: 1.875rem;
        }
        .segmentbutton{
            margin-top: 1.875rem;
        }
    }
}

.stickytopRecomd{
    top: 4.5rem !important;
    background: $white;
}