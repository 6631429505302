@import '../../../../../../assets/styles/styles.scss';

.splitbox {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .splitcol {

        &.split-1st {
            width: 30%;
            position: relative;

            &::after {
                position: absolute;
                content: '';
                right: -.32rem;
                bottom: -1rem;
                width: .63rem;
                height: 1rem;
                background: url('../../../../../../assets/images/scroller-icon.svg') no-repeat scroll center center;
            }
        }

        &.split-2nd {
            width: 70%;
        }

        p {
            font-weight: 500;
            font-size: .88rem;
            line-height: 1.07rem;
            color: $black;
            margin: 0 0 .5rem;
        }

        .splitcol-inner {
            height: 2.63rem;
            font-weight: 700;
            font-size: 1rem;
            line-height: 2.63rem;
            color: $white;

            &.splitA {
                background: $mai-green-shade;
                @include border-radius(.32rem, 0, 0, .32rem);
            }

            &.splitB {
                background: $mai-orange-shade3;
                @include border-radius(0, .32rem, .32rem, 0);
            }
        }

    }
}

.runtimebox {
    border: .07rem solid rgba(19, 34, 149, 0.1);
    @include border-radius();
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 8.75rem;

    .timeselectcol {
        width: 50%;

        &:first-child {
            border-right: .07rem solid rgba(19, 34, 149, 0.1);
        }

        input {
            font-weight: 400;
            font-size: .88rem;
            line-height: 1.32rem;
            color: $black;
            border: none;
            height: 2.5rem;

            &::placeholder {
                color: $black;
            }
        }

        .p-dropdown {
            width: 100%;
            height: 2.5rem;
            border: none;

            &:not(.p-disable) {
                &.p-focus {
                    box-shadow: none;
                }
            }

            .p-dropdown-label {
                width: auto;
                padding: .5rem .63rem .5rem 0;
                text-align: right;
                font-weight: 400;
                font-size: .88rem;
                line-height: 1.32rem;
                color: $black;
            }

            .p-dropdown-trigger {
                width: auto;

                .p-dropdown-trigger-icon {
                    font-size: .63rem;
                    color: $black;
                    padding-right: .44rem;
                }
            }
        }
    }
}