@import '../../../../../assets/styles/styles.scss';

.customer__segment-chart {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: $mai-white-shade;
    border-radius: 0.5rem;
    width: 100%;
    height: 100%;

    .customer_chart {
        text-align: center;

        img {
            width: 12.5rem;
        }

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.188rem;
            text-align: center;
            color: $black;
            margin: 1rem 0 0;
        }
    }

    .customer-segments-list {
        margin: 0;
        padding: 0;

        li {

            font-style: normal;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.063rem;
            color: $black;
            margin-bottom: 0.938rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                font-size: 0.438rem;
                margin-right: 0.313rem;

                &.color1 {
                    color: $mai-green-shade1;
                }

                &.color2 {
                    color: $mai-blue-shade;
                }

                &.color3 {
                    color: $mai-macroni-cheese;
                }

                &.color4 {
                    color: $mai-blue-shade1;
                }

                &.color5 {
                    color: $mai-orange-shade;
                }
            }

            // &::marker {
            //     font-size: 1.313rem;
            //     line-height: 0.625rem;
            // }

            // &:first-child::marker {
            //     color: #87D6B4;
            // }

            // &:nth-child(2)::marker {
            //     color: #8592DE;
            // }

            // &:nth-child(3)::marker {
            //     color: $mai-macroni-cheese;
            // }

            // &:nth-child(4)::marker {
            //     color: $mai-blue-shade1;
            // }

            // &:nth-child(5)::marker {
            //     color: $mai-orange-shade;
            // }

            p {
                margin: 0;

                strong {}

                span {
                    color: $mai-cool-gray;
                    font-size: 0.75rem;
                }
            }

            .light-text {
                color: $mai-cool-gray;
                font-size: 0.75rem;
            }
        }
    }
}

.audience-dashboard-list {

    li {
        font-size: 1.313rem !important;

        i {
            font-size: 0.563rem !important;
        }
    }
}

.boxshadow {
    box-shadow: 0 0.25rem 0.5rem $mai-light-silver;
    background: $white;
}

.increaserate {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.125rem;
    color: $mai-eerie-black;

    span {
        background: $mai-green-shade2;
        border-radius: 0.25rem;
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: $mai-green-shade3;
        display: inline-block;
        margin-left: 0.25rem;
    }
}

.chart-title {
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: $mai-cool-gray;
    margin: 0 0 0.625rem;
}

.brandequity-wrapper {

    .insights-data {

        li {

            h2 {

                .positive {

                    span {
                        font-weight: 400;
                        font-size: 0.75rem;
                        color: $mai-green-shade;
                    }

                    p {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 1.25rem;
                        line-height: 1.125rem;
                        color: $mai-green-shade;
                        margin: 0;
                    }
                }

                .negative {

                    span {
                        font-weight: 400;
                        font-size: 0.75rem;
                        color: $mai-red-shade1;
                    }

                    p {

                        font-style: normal;
                        font-weight: 700;
                        font-size: 1.25rem;
                        line-height: 1.125rem;
                        color: $mai-red-shade1;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.addkpi {
    background: $mai-alice-blue;
    border: 0.063rem dashed $mai-cadet-blue;
    border-radius: 0.313rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 0;
    cursor: pointer;

    img {
        display: inline-block;
        margin-right: 0.438rem;
    }

    p {
        display: inline-block;
        margin: 0;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.375rem;
        color: $mai-cadet-blue;
    }
}

.dropdownmain-wrapper {

    .dropdownmenu-wrapper {

        .dropdown-menu {
            transform: translate(-5.438rem, 1.563rem) !important;
        }
    }
}

.dropdownmain-wrapper-end {

    .dropdownmenu-wrapper {

        .dropdown-menu {
            transform: translate(0, 1.563rem) !important;
        }
    }
}

.customtabs-wrap {
    .customtabs {

        .p-tabview {

            .p-tabview-nav-container {

                .p-tabview-nav-content {

                    .p-tabview-nav {

                        li {

                            &.p-tabview-selected {
                                a {
                                    font-size: 0.875rem;

                                    &:hover {
                                        color: $mai-blue-primary-1;
                                    }
                                }
                            }

                            a {
                                padding: 0.5rem 0.5rem;
                                margin: 0.188rem 0.5rem;
                                font-size: 0.875rem;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.customtabs-wrap {
    .customtabs {

        .p-tabview {

            .p-tabview-nav-container {

                .p-tabview-nav-content {

                    .p-tabview-nav {

                        li {
                            border-right: none;
                            padding: 0;

                            &.p-tabview-selected {
                                a {
                                    font-size: 0.875rem;

                                    &:hover {
                                        color: $mai-blue-primary-1;
                                    }
                                }
                            }

                            a {
                                padding: 0.45rem 0.7rem;
                                margin: 0.188rem 0.2rem;
                                font-size: 0.875rem;

                                &:hover {
                                    opacity: .5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}