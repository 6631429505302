@mixin base($color) {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: $color;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  //   "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  //   "Helvetica Neue", sans-serif;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@mixin disableBtn {
  background-color: $mai-stone-color;
  border-color: $mai-stone-color;
}

// Search box stylesheet
@mixin searchbox {
  .input-group {
    background: $mai-gray-primary-3;
    border: 0.063rem solid rgba(19, 34, 149, 0.1);
    height: 2.375rem;

    @include border-radius();

    #button-addon4 {
      padding: 0.313rem 0.625rem;
      line-height: 1.188rem;
    }

    .form-control {
      background: $mai-gray-primary-3;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.313rem;
      color: $mai-dim-gray ;
      padding: 0;
      height: 2.25rem;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

// Cancel button
@mixin cancelButton {
  min-width: 7rem;
  height: 2.5rem;
  background: rgba(0, 109, 228, 0.2);
  text-align: center;
  color: $mai-navy-blue;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.313rem;
  border: none;

  @include border-radius();

  &:hover {
    background: rgba(0, 109, 228, 0.2) !important;
    color: $mai-navy-blue !important;
  }
}

// Next button
@mixin nextButton {
  width: 7rem;
  height: 2.5rem;
  background: $mai-blue-primary-1;
  text-align: center;
  color: $white;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  border: none;

  @include border-radius();

  &:hover {
    background-color: $mai-blue-primary-1 !important;
    border-color: $mai-blue-primary-1 !important;
  }
}

// Update button
@mixin updateButton {
  width: 7rem;
  height: 2.5rem;
  background: $mai-cool-gray;
  text-align: center;
  color: $white;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  border: none;

  @include border-radius();

  &:hover {
    background-color: $mai-cool-gray !important;
    border-color: $mai-cool-gray !important;
  }
}

@mixin modalTitle {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: $black;
}

@mixin modalClose {
  color: $mai-eerie-black;
  font-size: 0.75rem;
  opacity: 0.7;

  &:focus {
    box-shadow: none;
  }
}

@mixin modalHeader {
  border: none;

  button {
    &.popup-close {
      color: $mai-eerie-black;
      font-size: 0.75rem;
      opacity: 0.7;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

@mixin modalFooter {
  border: none;
}

// .popup-title {
//   font-weight: 700;
//   font-size: 1.25rem;
//   line-height: 1.75rem;
//   color: $black;
// }

// .popup-close {
//   color: $mai-eerie-black;
//   font-size: 0.75rem;
//   opacity: 0.7;

//   &:focus {
//     box-shadow: none;
//   }
// }

// .modal-header {
//   border: none;
// }

// .modal-footer {
//   border: none;
// }

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin border-radius($top-left: .313rem, $top-right: .313rem, $bottom-right: .313rem, $bottom-left: .313rem) {
  border-radius: $top-left $top-right $bottom-right $bottom-left;
}

@mixin selectlist {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: .44rem 0;

  .selectlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 15px;

    label {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $mai-gray-primary-7;
    }

    .p-dropdown-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $mai-blue-shade2;
    }

    .p-dropdown-trigger-icon {
      color: $mai-blue-shade2;
    }
  }

}

@mixin titleFonts18 {
  font-weight: 700;
  font-size: 1.13rem;
  line-height: 1.25rem;
  color: $mai-dark-gray;
}

@mixin titleFonts16 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.25rem;
  color: $mai-dark-gray;
}

@mixin chartindicator {
  ul {
    li {
      margin: 0 0 0 1rem;
      font-weight: 400;
      font-size: .88rem;
      line-height: 22px;
      color: $black;

      img {
        margin-right: 5px;
      }
    }
  }
}

@mixin radiobutton {
  background-color: $mai-stone-color;
  border: none;
  width: 0.563rem;
  height: 0.563rem;
  opacity: 0.4;
  margin-top: 0.438rem;
  margin-left: -1rem;

  &.form-check-input:checked[type="radio"] {
    background-image: none;
  }

  &:focus {
    box-shadow: none;
  }

  &:checked {
    background-color: $mai-blue-primary-4 ;
    border-color: $mai-blue-primary-4 ;
    opacity: 1;
    background-image: none;
  }
}

@mixin radioLabel {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.125rem;
  color: $mai-dark-gray;
}

@mixin optionaltitle {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: $mai-gray-primary-7;
  margin-left: 0.313rem;
}

@mixin sortable-icon {

  .pi-sort-alt {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 9px;
      height: 12px;
      top: -5px;
      left: 0;
      background: url('../../assets/images/shorting-icon.svg') no-repeat scroll center center;
      background-size: cover;

    }
  }
}

@mixin customcheckbox {
  background-color: $white;
  border: 0.063rem solid $mai-blueshade;
  @include border-radius(.13rem, .13rem, .13rem, .13rem);

  &:checked {
    background-color: $mai-blue-shade2;
    border-color: $mai-blue-shade2;
  }
}

@mixin whiteboxheight {
  min-height: 532px;
}

@mixin inputfield {
  background-color: $mai-gray-primary-3;
  border: 0.063rem solid rgba(19, 34, 149, 0.1);
  color: $mai-grayshade4;
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
  height: 35px;
  @include border-radius();

  .p-dropdown-label {
    color: $mai-grayshade4;
    font-size: 0.875rem;
    font-weight: 400;
    padding: .44rem 10px;
  }

  .p-dropdown-trigger-icon {
    font-size: 12px;
  }

  &::focus {
    box-shadow: none;
    outline: none;
  }

  &.p-dropdown {
    &:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: rgba(19, 34, 149, 0.1);
    }
  }
}

@mixin calendaricon {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 13px;
    background-image: url('../../assets/images/calendar-grey-icon.svg');
  }
}

@mixin normaltitleblack {
  font-weight: 400;
  font-size: .88rem;
  line-height: 1.07rem;  
  color: $black;
}