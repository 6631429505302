@import '../../../../../assets/styles/styles.scss';

.audiencelist {

    .audiencelist__select {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        label {
            width: 5.813rem;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.188rem;
            color: $mai-gray-primary-7;
            text-align: right;
        }

        select {
            width: 3.125rem;
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.313rem;
            color: $mai-blue-primary-1;
            border: none;
            padding-left: 0.625rem;
            padding-right: 0.938rem;
            background-position: right;

        }
    }

    .welcome__create-anchor {
        background: $mai-blue-primary-1;
        height: 2.5rem;
        display: inline-block;
        padding: 0 1rem;
        color: $white;
        text-decoration: none;
        line-height: 2.5rem;
        font-size: 0.875rem;
        font-weight: 400;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);

        .welcome__plus-icon {
            margin-right: 0.313rem;
        }
    }
}

.audiencelist-datatable {
    .p-datatable-wrapper {
        max-height: 27.8rem !important;
        min-height: 27.8rem;
    }

    .p-datatable-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 .063rem $white;
        @include border-radius(0, 0, 0, 0);
        background-color: transparent;
    }

    .p-datatable-wrapper::-webkit-scrollbar {
        width: .35rem;
        background-color: transparent;
        height: .32rem;
    }

    .p-datatable-wrapper::-webkit-scrollbar-thumb {
        @include border-radius();
        background: $mai-gray-shade6;
        min-height: 6.25rem;
    }


}

.customer-badge {
    padding: 0.25em 0.5rem;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.019rem;

    @include border-radius(.125rem, .125rem, .125rem, .125rem);
}

.customer-badge.status-published {
    background: $mai-green-shade3;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: $mai-green-shade3;
    @include border-radius(.25rem, .25rem, .25rem, .25rem);
}

.customer-badge.status-draft {
    background: rgb(230 152 100 / 10%);
    @include border-radius(.25rem, .25rem, .25rem, .25rem);
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-align: center;
    color: $mai-orangeshade;
}

.listpagination {
    margin: 1rem 0 0;
    padding: 0;

    &.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    &.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    &.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    &.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background: transparent;
    }

    button {
        &.p-paginator-element {
            height: 1rem;
            min-width: 1rem;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .p-button {
        height: auto;
        padding: 0.313rem 0;
        min-width: 1rem;
        width: 1rem;

        &:focus {
            box-shadow: none;
        }
    }

    .p-paginator-pages {
        line-height: 1rem;
    }

    .p-paginator-pages .p-paginator-page {
        min-width: 1.3rem;
        height: 1.3rem;
        margin-top: 0;
        margin-bottom: 0;
        color: $black;
        font-size: 1rem;
        background-color: $mai-whitesmoke-shade;
        border-radius: .19rem;
        padding: .32rem;
    }

    .p-paginator-pages .p-paginator-page.p-highlight {
        background: $mai-blue-shade2;
        border-color: $mai-blue-shade2;
        color: $white;

        &:focus {
            box-shadow: none;
        }
    }

    .p-paginator-last {
        display: none;
    }

    .p-paginator-first {
        display: none;
    }
}

.p-menu {
    width: 14.75rem;
    margin-top: 8px;

    .p-menuitem {
        &:first-child {
            display: none;
        }
    }

    .p-menuitem-link {
        padding: 0.4rem 1.25rem;

        &:hover {
            background: rgb(180 220 242 / 30%) !important;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .p-menuitem-text {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $black !important;
    }

    .p-menuitem-icon {
        color: $mai-blue-primary-1 !important;
        font-size: 0.875rem;
    }
}

.p-datatable-emptymessage {

    td {
        white-space: pre-wrap !important;
        max-width: 100% !important;
    }
}

.audiencelist-datatable {
    .p-paginator {
        margin: 1rem 0 0;
        padding: 0;
    }
}

.card {
    @include sortable-icon;
}

.listpage-table {
    .p-datatable {
        &.p-datatable-selectable-cell .p-datatable-tbody>tr.p-selectable-row>td.p-selectable-cell:not(.p-highlight):hover {
            color: $black;
        }

        .p-datatable-table {
            thead {
                th {
                    max-width: 11%;

                    &:first-child {
                        max-width: 17%;
                    }

                    &:nth-child(2) {
                        max-width: 17%;
                    }

                    &:nth-child(5) {
                        max-width: 15%;
                    }

                    &:nth-child(7) {
                        max-width: 10%;
                    }

                    &:last-child {
                        max-width: 8%;
                    }
                }
            }

            tbody {
                td {
                    max-width: 11%;
                    min-height: 1rem;

                    &:first-child {
                        max-width: 17%;
                        // overflow: hidden !important;
                        white-space: nowrap !important;
                        // text-overflow: ellipsis;
                        // display: block;
                        padding: .88rem .5rem !important;
                    }

                    &:nth-child(2) {
                        max-width: 17%;
                        // overflow: hidden !important;
                        white-space: nowrap !important;
                        // text-overflow: ellipsis;
                        // display: block;
                        padding: .88rem .5rem !important;
                    }

                    &:nth-child(3) {
                        font-weight: 600;
                    }

                    &:nth-child(5) {
                        max-width: 15%;
                    }

                    &:nth-child(7) {
                        max-width: 10%;
                    }

                    &:last-child {
                        max-width: 8%;
                    }
                }
            }
        }
    }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: 0.5rem 0.5rem;
    font-size: .88rem;
}

.common-popup-wrap {
    .p-dialog {
        width: 28rem;

        .p-dialog-header {
            padding: 1rem 1.5rem;

            .p-dialog-title {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.7rem;
            }

            .p-dialog-header-icon {
                color: $mai-eerie-black;

                &:enabled {
                    &:hover {
                        background: transparent;
                        color: $mai-eerie-black;
                    }
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .p-dialog-content {
            padding: 1rem 1.5rem;

            span {
                &.p-confirm-dialog-message {
                    margin-left: 0;
                }
            }
        }

        .p-dialog-footer {
            padding: 1.5rem;
            flex-direction: row-reverse;
            display: flex;

            .p-confirm-dialog-reject {
                background: $mai-blue-shade2;
                border: none;
                @include border-radius();
                color: $white;
                font-size: .875rem;
                font-weight: 400;
                height: 2.5rem;
                line-height: 1.313rem;
                text-align: center;
                width: 7rem;
                margin-right: 0;

                &:focus {
                    box-shadow: none;
                }
            }

            .p-confirm-dialog-accept {
                background: transparent;
                border: none;
                @include border-radius();
                color: $mai-navy-blue;
                font-size: .875rem;
                font-weight: 600;
                height: 2.5rem;
                line-height: 1.313rem;
                text-align: center;
                width: 7rem;

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    &.sharewith-modalpopup {
        .p-dialog {
            .p-dialog-content {
                .form-control {
                    padding: 0;
                    border: 0;
                    border: 0.063rem solid rgba(19, 34, 149, 0.1);
                    border-radius: 0.313rem 0.313rem 0.313rem 0.313rem;

                    ul {
                        background: $mai-gray-primary-3;
                        color: $black;
                        font-size: 0.875rem;
                        font-weight: 500;
                        padding: 0 0.13rem;
                        border: none;

                        &:not(.p-disabled) {
                            &:hover {
                                border: none;
                            }

                            &.p-focus {
                                box-shadow: none;
                            }
                        }

                        li {
                            color: $black;
                            margin: 0.13rem;
                            padding: 0.25rem 0.75rem;

                            .p-autocomplete-token-label {
                                color: $mai-blue-shade2;
                            }

                            &.p-autocomplete-input-token {

                                input {
                                    &::placeholder {
                                        font-size: 0.875rem;
                                        line-height: 1.125rem;
                                        color: $mai-gray-primary-2;
                                    }
                                }
                            }

                            .p-autocomplete-token-icon {
                                position: relative;

                                &::before {
                                    position: absolute;
                                    content: '';
                                    width: 1rem;
                                    height: 1rem;
                                    background: url('../../../../../assets/images/dismiss-circle.png') no-repeat scroll top center;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    right: -.63rem;
                                }
                            }
                        }
                    }
                }
            }

            .p-dialog-footer {
                button {
                    background: $mai-blue-shade2;
                    border: none;
                    border-radius: 0.313rem 0.313rem 0.313rem 0.313rem;
                    color: $white;
                    font-size: 0.875rem;
                    font-weight: 400;
                    height: 2.5rem;
                    line-height: 1.313rem;
                    text-align: center;
                    width: 7rem;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

            }
        }
    }
}

.list-tooltip-wrap {
    max-width: 21.88rem;

    .p-tooltip-text {
        padding: .44rem !important;
        line-height: 1.19rem;
        font-size: .88rem;
        color: $white;
        background: $mai-davys-grey;
        margin-left: .32rem;
    }

    .p-tooltip-arrow {
        margin-left: .32rem;
    }

    &.waterfallchart-tooltip {
        .p-tooltip-text {
            margin-top: .5rem;
            padding: .44rem 1.57rem !important;
        }

        .p-tooltip-arrow {
            margin-top: .5rem;
        }
    }
}

.heatmap-tab-wrap {
    .heatmap-wrapper {
        .p-tabview {
            position: relative;

            .p-tabview-nav-container {
                position: absolute;
                top: -.44rem;
                right: 48%;

                .p-tabview-nav {}
            }

            .p-tabview-panels {
                padding: 1rem 0 0;
            }
        }

    }
}

.p-multiselect-panel {

    .p-multiselect-header {
        padding: 0.75rem 1.25rem;
        border-bottom: none;
        background: $white;

        .p-checkbox {
            width: 1.13rem;
            height: 1.13rem;

            .p-checkbox-box {
                border: .13rem solid $mai-gray-shade;
                background: $mai-gray-shade;
                width: 1.13rem;
                height: 1.13rem;
                border-radius: .13rem;

                &.p-highlight {
                    border-color: $mai-blue-primary-1;
                    background: $mai-blue-primary-1;
                }
            }

            &:not(.p-checkbox-disabled) {
                .p-checkbox-box {
                    &.p-focus {
                        box-shadow: none;
                    }
                }
            }
        }

        .p-multiselect-close {
            color: $black;

            &:focus {
                box-shadow: none;
            }

            .p-multiselect-close-icon {
                font-weight: 600;
            }

            &:enabled {
                &:hover {
                    background: transparent;
                }
            }
        }

        input {
            padding: .38rem 0 .38rem .38rem;
            background: $mai-gray-primary-3;
            border: 0.06rem solid rgba(19, 34, 149, .1);
            border-radius: .32rem;

            &:enabled {
                &:focus {
                    box-shadow: none;
                    border-color: rgba(19, 34, 149, .1);
                }

                &:hover {
                    box-shadow: none;
                    border-color: rgba(19, 34, 149, .1);
                }
            }
        }
    }

    .p-multiselect-items-wrapper {
        border-radius: .32rem;
        overflow: scroll;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 .063rem $white;
            @include border-radius(0, 0, 0, 0);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: .35rem;
            background-color: transparent;
            height: .32rem;
        }

        &::-webkit-scrollbar-thumb {
            @include border-radius();
            background: $mai-gray-shade6;
            min-height: 6.25rem;
        }

        .p-multiselect-items {
            padding: 0;

            .p-multiselect-item {
                color: $black;
                background: $white;
                padding: .32rem 1.25rem;
                line-height: .94rem;

                &:not(.p-highlight) {
                    &:not(.p-disabled) {
                        &:hover {
                            color: $black;
                            background: transparent;
                        }
                    }
                }

                &.p-highlight {
                    color: $black;
                    background: $white;
                }

                &:focus {
                    box-shadow: none;
                }

                .p-checkbox {
                    width: 1.13rem;
                    height: 1.13rem;

                    .p-checkbox-box {
                        border: .13rem solid $mai-gray-shade;
                        background: $mai-gray-shade;
                        width: 1.13rem;
                        height: 1.13rem;
                        border-radius: .13rem;

                        &.p-highlight {
                            border-color: $mai-blue-primary-1;
                            background: $mai-blue-primary-1;
                        }
                    }
                }
            }
        }
    }

}

.heatmap-tab-wrap {

    .heatmap-column-top {

        &.industry-column {

            .selectlist-wrap {
                .selectlist {
                    label {
                        color: $mai-gray-primary-7;
                        font-size: .88rem;
                        font-weight: 400;
                        line-height: 1.32rem;
                    }

                    .p-dropdown-label {
                        overflow: hidden;
                        text-align: center;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 10rem;
                    }

                    .p-dropdown-trigger-icon {
                        margin: .25rem 0 0;
                    }

                    .p-multiselect {
                        .p-multiselect-trigger {
                            width: 2rem;
                        }
                    }
                }
            }

            .p-multiselect {
                border: none;

                &:not(.p-disabled) {
                    &.p-focus {
                        box-shadow: none;
                    }
                }

                .p-multiselect-label {
                    padding: .32rem 0.75rem;
                    background: $mai-blue-shade6;
                    border-radius: .32rem;
                    color: $mai-blue-primary-1;
                    max-width: 10.94rem;
                }

                .p-multiselect-trigger-icon {
                    color: $mai-blue-primary-1;
                    font-size: .88rem;
                    font-weight: 700;
                }
            }

        }
    }
}


.heatmap-tilesbox-wrapper {
    max-height: 24.9rem;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: .06rem;
}

.tiles-box {
    border: .07rem solid $mai-gray-shade4;
    padding: .5rem;
    border-radius: .5rem;
    margin-bottom: .44rem;

    .btn-heatmap {
        display: none;
    }

    &.tiles-shadow {
        box-shadow: 0 .25rem .32rem rgb(0 0 0 / 25%);
        margin-bottom: .63rem;
        border-radius: .5rem;
        border-color: #eaeaea;
        background-color: $white;


        .btn-heatmap {
            display: block;
            margin-top: .44rem;
        }
    }

    h3 {
        color: $mai-dark-gray;
        font-size: .94rem;
        font-weight: 600;
        margin: 0 0 .32rem;
    }

    .tilesbox-titels {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1.19rem;
        margin-top: .44rem;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-top: 0;
        }

        p {
            color: $mai-cool-gray;
            font-size: .82rem;
            line-height: 1rem;
        }

        strong {
            font-weight: 600;
            font-size: .94rem;
            color: $black;

            &.heighlited-color {
                color: $mai-blue-primary-1;
            }

            span {
                color: $mai-cool-gray;
                font-size: .82rem;
                display: inline-block;
                margin-left: .5rem;
            }
        }
    }
}

.map-h {
    min-height: 24.5rem;
}

.mapwrap {
    position: relative;
    background: $mai-light-gray;
    @include border-radius(.32rem, .32rem, 0, 0);
    padding: .32rem;
    height: calc(100vh - 15.13rem);
    overflow: hidden;

    .zoomin-listicon {
        position: absolute;
        right: .63rem;
        top: .63rem;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            box-shadow: 0 0 .32rem $mai-light-gray-shade;
            display: inline-block;
            border-radius: .32rem;
            width: 1.88rem;
            height: 1.88rem;
            text-align: center;
            padding: .07rem 0;
            margin: 0;
            background: $white;
            text-decoration: none;
            font-size: 1.44rem;
            line-height: 1.06rem;
            color: $mai-blue-shade2;
            border: none;

            &:focus {
                box-shadow: 0 0 .32rem $mai-light-gray-shade;
                outline: none;
            }
        }

        .zoomin-out {
            display: inline-block;
            border-radius: 0.32rem;
            height: 1.88rem;
            text-align: center;
            padding: .07rem 0;

            button {
                background: $white;
                border: none;
                border-right: .06rem solid $mai-alice-blue-shade;
                width: 2.19rem;
                height: 1.75rem;
                border-radius: .32rem 0 0 .32rem;
                font-size: 1.44rem;
                line-height: 1.44rem;
                color: $mai-blue-shade2;

                &:last-child {
                    border-right: none;
                    border-radius: 0 .32rem .32rem 0;
                }

                &:focus {
                    box-shadow: 0 0 0.32rem $mai-light-gray-shade;
                    outline: none;
                }

                &.zoomin {
                    &:disabled {
                        color: darkgray;
                    }
                }

                &.zoomout {
                    img {
                        filter: brightness(2) invert(1);
                    }

                    &:disabled {
                        color: darkgray;

                        img {
                            filter: brightness(3) invert(1);
                        }
                    }
                }
            }
        }

        a {
            box-shadow: 0 0 .32rem $mai-light-gray-shade;
            display: inline-block;
            border-radius: .32rem;
            width: 1.88rem;
            height: 1.88rem;
            text-align: center;
            padding: .07rem 0;
            margin: 0 0 0 .63rem;
            background: $white;
            text-decoration: none;
            font-size: 1.44rem;
            line-height: 1.44rem;
            color: $mai-blue-shade2;

            &:focus {
                box-shadow: 0 0 .32rem $mai-light-gray-shade;
            }
        }

    }
}

.maphighlighter-wrap {
    position: relative;
    background: $mai-light-gray;
    @include border-radius(0, 0, .32rem, .32rem);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 10px;

    .year22 {
        font-size: .82rem;
        font-style: italic;
        line-height: .82rem;
    }

    .maphighlighter {
        position: relative;
        display: inline-block;
        bottom: 0;
        left: 35%;
        transform: translateX(-35%);
        line-height: .94rem;

        .highlow-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $black;
            line-height: .94rem;
            font-size: .82rem;
            font-weight: 500;
        }
    }
}


.icon-Zoomfit:before {
    content: "\e903";
}

.icon-Zoomin:before {
    content: "\e904";
}

.icon-Zoomout:before {
    content: "\e905";
}

.p-tooltip {
    &.custom-tooltip {
        background: $mai-dark-shade;
        border-radius: .32rem;
        // padding: .38rem .88rem;
    }
}

.custom-tooltip {
    &.p-tooltip-arrow {
        background: $mai-dark-shade;
    }
}

.audience-listwrap {
    border-top: .07rem solid $mai-white-smoke;
}

.p-sortable-column {
    .p-column-header-content {
        span[data-pc-section="sort"] {
            width: .57rem;
            height: .75rem;
            background: url("../../../../../assets/images/shorting-icon.svg") no-repeat scroll top center;
            background-size: 100%;
            margin-left: .32rem;

            .p-sortable-column-icon {
                display: none;
            }
        }
    }

    &.p-highlight {
        .p-column-header-content {
            span[data-pc-section="sort"] {
                background: transparent;

                .p-sortable-column-icon {
                    display: block;
                    margin-left: 0;
                }
            }
        }
    }
}

.list-datatable {

    .p-datatable .p-datatable-thead>tr>th.p-align-center>.p-column-header-content,
    .p-datatable .p-datatable-tbody>tr>td.p-align-center,
    .p-datatable .p-datatable-tfoot>tr>td.p-align-center {
        justify-content: flex-start;
    }
}