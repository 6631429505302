@import '../../../../../../assets/styles/styles.scss';

.recomdattribute-wrapper {
    height: calc(100vh - 6.813rem);

    .audiencecreate__form {

        .rules__wrapper {
            .accordion-header {

                .accordion-button {
                    font-size: 1rem;
                    padding: .438rem .938rem;

                    .accordian-text {
                        width: 50%;
                        text-align: left;
                    }

                    .attributeNumb {
                        font-style: normal;
                        font-weight: 600;
                        font-size: .875rem;
                        line-height: 1.5rem;
                        color: $mai-blueshade3;
                        width: 50%;
                        text-align: right;
                        padding-right: .625rem;
                    }
                }
            }
        }

    }
}

.stickytopRecomd {
    top: 4.5rem !important;
    background: $white;
}

.rules__bluedots {
    width: .938rem;
}

.rules__varfield {
    width: calc(100% - 1.875rem);
    text-align: left;
    padding: 0 .188rem;
}

.rules__arrowright {
    width: .938rem;
    text-align: center;
}