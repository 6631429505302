@import '../../../../assets/styles/styles.scss';

.dashboard-column {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.063rem;
    color: $black;
    align-items: flex-start !important;

    img {
        margin-right: 0.625rem;
    }

    span {}
}

.subtext {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: $black;
    opacity: 0.4;
    display: block;
    margin-top: 0.188rem;
}

.lastseen {

    p {
        margin: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: $black;
        opacity: 0.4;
    }
}

.dots3 {
    color: $mai-eerie-black;
    font-size: 0.813rem;
    cursor: pointer;
}