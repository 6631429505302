@import '../../../../../assets/styles/styles.scss';

.monitoring-dropdownlist {

    .p-dropdown {
        border: none;
        margin-left: .63rem;

        .p-dropdown-label {
            padding: 0;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.31rem;
            color: $mai-gray-primary-7;
        }

        .p-dropdown-trigger-icon {
            font-size: .63rem;
        }

        &.p-focus {
            outline: none;
            box-shadow: none;
        }

        .p-dropdown-trigger {
            width: 2rem;
        }

    }

}

.p-dropdown-panel {
    min-width: 8.13rem;
    width: 8.13rem;
    box-shadow: 0 .25rem .63rem rgba(43, 43, 43, 0.2);
    @include border-radius(.25rem, .25rem, .25rem, .25rem);

    .p-dropdown-items-wrapper {
        max-height: 15.63rem !important;

        .p-dropdown-items {
            .p-dropdown-item {
                padding: 0.5rem 1rem;
                color: $black;
                margin-bottom: .32rem;
                font-size: .88rem;
                line-height: 1.06rem;
                white-space: pre-wrap;

                &.p-highlight {
                    background: $mai-ice-castle;
                }

                &:not(.p-highlight) {
                    &:not(.p-disabled) {
                        &:hover {
                            background: $mai-ice-castle;
                            color: $black;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.selectlist-wrap {
    @include selectlist;
}

.campaign-list {
    thead {
        th {
            &:first-child {
                max-width: 19.5%;
            }

            &:nth-child(2) {
                max-width: 18%;
            }

            &:nth-child(3) {
                max-width: 13% !important;
            }

            &:nth-child(4) {
                max-width: 16%;
            }
        }
    }

    tbody {

        td {
            &:first-child {
                max-width: 19.5%;
            }

            &:nth-child(2) {
                max-width: 18%;
            }

            &:nth-child(3) {
                max-width: 13% !important;
            }

            &:nth-child(4) {
                max-width: 16%;
                overflow: visible;
            }
        }
    }
}