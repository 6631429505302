@import '../../../../../../assets/styles/styles.scss';

.roadmapDate {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .7rem .5rem;
    position: relative;
    @include border-radius();

    img {
        margin-right: .63rem;
    }

    p {
        font-weight: 700;
        font-size: .88rem;
        line-height: 1.07rem;
        color: $white;
        margin: 0;

        span {
            font-weight: normal;
            display: block;
        }
    }

}

.roadmapDateGreen {
    background: $mai-green-shade;

    &::after {
        position: absolute;
        right: -2rem;
        top: 50%;
        content: "";
        background: $mai-green-shade;
        border: .13rem solid $white;
        width: .75rem;
        height: .75rem;
        transform: translateY(-50%);
        @include border-radius(50%, 50%, 50%, 50%);
    }

    &::before {
        position: absolute;
        content: '';
        right: -1.7rem;
        top: 50%;
        width: .06rem;
        height: 50%;
        border-right: .094rem dashed $mai-blueshade2;
    }
}

.roadmapDateRed {
    background: $mai-red-shade1;

    &::after {
        position: absolute;
        right: -2rem;
        top: 50%;
        content: "";
        background: $mai-red-shade1;
        border: .13rem solid $white;
        width: .75rem;
        height: .75rem;
        transform: translateY(-50%);
        @include border-radius(50%, 50%, 50%, 50%);
    }

    &::before {
        position: absolute;
        content: '';
        right: -1.7rem;
        bottom: 50%;
        width: .06rem;
        height: 50%;
        border-right: .094rem dashed $mai-blueshade2;
    }
}

.roadmapDot {}

.editDate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 4rem;
    position: relative;

    &.selectDate {
        cursor: pointer;

        p {
            &.selectDateTitle {
                color: $mai-blue-primary-4;
            }
        }

        img {
            &.calendarIcon {
                opacity: 1;
            }
        }
    }

    &::after {
        position: absolute;
        content: '';
        right: -2rem;
        top: 50%;
        background: $mai-stone-color;
        border: .13rem solid $white;
        width: .75rem;
        height: .75rem;
        transform: translateY(-50%);
        @include border-radius(50%, 50%, 50%, 50%);
    }

    &::before {
        position: absolute;
        content: '';
        right: -1.7rem;
        top: 0;
        width: .06rem;
        height: 100%;
        border-right: .094rem dashed $mai-blueshade2;
    }

    &.addEmailBorder {
        &::after {
            background: transparent;
        }
    }

    p {
        margin: 0;
        font-size: .82rem;
        font-weight: 400;
        line-height: 1rem;
        color: $black;
    }

    img {
        margin-left: .32rem;
        opacity: 0.2;
    }
}

.emailEditSec {
    width: 98%;
    background: $white;
    border: .06rem dashed $mai-blueshade2;
    @include border-radius();
    min-height: 3.44rem;
    padding: .5rem .63rem;
    float: right;

    .emailEditInner {
        background: rgba(80, 72, 229, 0.1);
        @include border-radius();
        display: inline-block;
        padding: .63rem;

        &.redshade {
            background: rgba(249, 121, 28, 0.1);
        }

        &.blueshade {
            background: rgba(2, 103, 255, 0.15);
        }

        img {

            &.crossImg {
                opacity: 0.2;
                border-left: .06rem solid $black;
                padding: .13rem 0 .13rem .32rem;
            }
        }

        p {
            margin: 0 1rem 0 .32rem;
            font-weight: 500;
            font-size: .94rem;
            line-height: 1.07rem;
            color: $black;

            strong {
                font-weight: 700;
            }
        }

        .flexProperty {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.addEmailBtn {
    margin: 1rem;
}

.contactSEmailPop {
    cursor: pointer;
}

.selectDatepopWrap {

    .modal-dialog {
        max-width: 20.63rem;

        .modal-header {
            h1 {
                font-size: 1rem;
            }
        }

        form {
            .p-calendar {
                input {
                    &.p-inputtext {
                        background: $mai-gray-primary-3;
                        border: .06rem solid rgba(19, 34, 149, 0.1);                        
                        @include border-radius();
                        color: $black;
                        font-size: 1rem;
                        padding: .5rem .63rem;
                        height: 2.5rem;
                        width: 100%;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                }
            }
        }
    }
}

.chooserole-design {
    background: $white;
    box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.25);
    @include border-radius();
    padding: .63rem 0;
    display: none;

    &.openRoleModal {
        display: block !important;
    }
}

.plusminusmainwrap {
    padding: .32rem 1rem;

    .form-check {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        label {
            font-size: .88rem;
            color: $black;
            font-weight: 400;
        }

        .checkboxfield {
            &.form-check-input {
                width: .75rem;
                height: .75rem;
                margin-right: .32rem;
                @include customcheckbox;
            }
        }
    }

    &:hover {
        background: $mai-ice-castle;
    }
}

.plusminuswrap {
    background: $mai-gray-primary-3;
    border: .06rem solid rgba(19, 34, 149, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5rem;
    height: 2.07rem;
    @include border-radius();
    text-align: center;

    .plusminuswrap__inner {
        font-weight: 400;
        font-size: .88rem;
        color: $black;

        span {
            font-weight: 400;
            font-size: .88rem;
            color: $black;
            width: 2.5rem;
            height: 2.07rem;
            line-height: 2.07rem;
        }

        &.plusminuswrap__inner-middle {
            border-right: .06rem solid rgba(19, 34, 149, 0.1);
            border-left: .06rem solid rgba(19, 34, 149, 0.1);
            width: 2.5rem;
            height: 2.07rem;
            line-height: 2.07rem;
        }
    }
}

.role-area {
    border: .06rem solid gray;
    min-height: 1.13rem;
    padding: .13rem;
    resize: both;
    width: 25rem;

    .rolefield {
        background: rgba(80, 72, 229, 0.1);
        @include border-radius();
        padding: .63rem;
        display: inline-block;
        min-height: fit-content;

        .roleitem {
            display: flex;
            align-items: center;
            justify-content: center;

            .role-label {
                margin: 0 0.1rem 0 .32rem;
                font-weight: 700;
                font-size: .75rem;
                line-height: .69rem;
                color: $black;
            }

            .role-value {
                font-weight: 700;
                font-size: .75rem;
            }
        }
    }
}

.roadmap-wrapper {

    .rolechooser {
        .role-area {
            background: $mai-gray-primary-3;
            border: .06rem solid rgba(19, 34, 149, 0.1);
            @include border-radius();
            min-height: 2.5rem;

            .rolefield {
                background: $mai-ice-castle;
                @include border-radius();
                padding: .19rem;

                .roleitem {
                    font-weight: 400;
                    font-size: .88rem;
                    line-height: 1.32rem;
                    color: $black;
                    margin: 0;
                }
            }
        }
    }
}