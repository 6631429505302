#new_loader {
  position: relative;
}
.before_page_load {
    width: 100%;
    // height: 100vh;
    // background-color: #FFFFFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .overlay {
    opacity: 0.5;
  }
  .loaderoverlay{
    position: absolute;
    // this will stretch overlay to fill width and height of form
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .before_page_load.nav_loader {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1100;
  }
  
  @-webkit-keyframes loader_pulse {
    0% {
      -webkit-transform: scaley(1);
              transform: scaley(1); }
    50% {
      -webkit-transform: scaley(0.4);
              transform: scaley(0.4); }
    100% {
      -webkit-transform: scaley(1);
              transform: scaley(1); } }
  
  @keyframes loader_pulse {
    0% {
      -webkit-transform: scaley(1);
              transform: scaley(1); }
    50% {
      -webkit-transform: scaley(0.4);
              transform: scaley(0.4); }
    100% {
      -webkit-transform: scaley(1);
              transform: scaley(1); } }
  
  .loader_pulse > div {
    width: 4px;
    height: 40px;
    border-radius: 2px;
    margin: 4px;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    display: inline-block;
    -webkit-animation: loader_pulse 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
            animation: loader_pulse 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85); }
    .loader_pulse > div:nth-child(2), 
    .loader_pulse > div:nth-child(4) {
      -webkit-animation-delay: -0.4s !important;
              animation-delay: -0.4s !important; }
    .loader_pulse > div:nth-child(1), 
    .loader_pulse > div:nth-child(5) {
      -webkit-animation-delay: -0.2s !important;
              animation-delay: -0.2s !important; }
  
  .loader_pulse > div:nth-child(1),
  .loader_pulse > div:nth-child(5) {
    background-color: #b9bac5;
  }
  
  .loader_pulse > div:nth-child(2),
  .loader_pulse > div:nth-child(4) {
    background-color: #b9bac5;
  }
  
  .loader_pulse > div:nth-child(3) {
    background-color: #b9bac5;
  }