@import "../../../../../../assets/styles/styles.scss";

%steps {
  position: absolute;
  top: -0.125rem;
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background-color: $mai-blue-primary-1 !important;

  @include border-radius(50%, 50%, 50%, 50%);
}

.audiencecreate__steps {
  .steps__active-rules {
    background: $mai-blue-primary-1 !important;

    &::before {
      @extend %steps;
      left: 0;
    }

    &::after {
      @extend %steps;
      right: 0;
    }
  }
}

.rules__wrapper {
  .accordion-item {
    border: none;
  }

  .accordion-header {
    .accordion-button {
      font-size: 1.125rem;
      line-height: 1.125rem;
      color: $mai-dark-gray;
      font-weight: 600;
      padding: 0.625rem 0.938rem;
      border: none;
      box-shadow: none;
      background: $mai-aliceblue;

      &::after {
        background-size: 100%;
        height: 0.625rem;
        width: 0.75rem;
      }
    }
  }

  .rules__includeexclude-body {
    background: $mai-white-shade;
    border: .063rem dashed $mai-blueshade2;
    padding: .3rem .5rem .94rem;
    margin-top: .63rem;
    position: relative;
    min-height: 5rem;

    @include border-radius();

    .rules__includes-data {
      width: 84%;
      position: relative;
      margin-left: 2.2rem;

      .rules__inner-body {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: .63rem !important;

        &:last-child {
          + {
            .rules__and {
              display: none !important;
            }
          }
        }

        .rules__includes-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: $mai-ice-castle;
          padding: 0.313rem 0.625rem;
          width: 100%;

          @include border-radius();

          .rules__bluedots {
            width: 0.938rem;
          }

          .rules__varfield {
            width: calc(100% - 1.875rem);
            text-align: left;
            padding: 0 0.188rem;
          }

          .rules__arrowright {
            width: 0.938rem;
            text-align: center;
          }

          &:hover {
            cursor: pointer;
          }

          p {
            margin: 0;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 0.938rem;
            align-items: center;
            color: $black;
          }
        }

        .rules__crosslight {
          margin-left: 0.313rem;
          width: 10%;
          cursor: pointer;
        }
      }

      .rules__and {
        position: absolute;
        left: -2.125rem;
        z-index: 99;
        margin-top: -3.438rem;
        min-height: 2.313rem;

        span {
          margin: 0;
          font-weight: 600;
          background: $mai-ice-castle;
          font-size: 0.5rem;
          line-height: 0.625rem;
          color: $mai-dark-gray;
          padding: 0.125rem .625rem;
          text-transform: uppercase;
          position: absolute;

          @include border-radius(.5rem, .5rem, .5rem, .5rem);
        }

        .rules__and-border {
          width: 1.5rem;
          height: 2.313rem;
          content: "";
          position: absolute;
          left: 1.063rem;
          top: -0.625rem;
          background: url(../../../../../../assets/images/rules-and-border.svg) no-repeat scroll;
          background-size: 45%;
          z-index: -1;
        }
      }
    }

    .rules_group_wrapper_andorclub {
      border: 0.063rem solid $mai-blue-shde2;
      padding: 0.625rem;
      margin-right: 1rem;
      position: relative;
      width: 90%;

      @include border-radius(1.125rem, 1.125rem, 1.125rem, 1.125rem);
    }

    .rules_group_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .separator_box_width {
        right: -11.188rem;
      }
    }

    .rules__group {
      background: $mai-blue-primary-1;
      display: inline-block;
      font-size: 0.625rem;
      line-height: 0.75rem;
      color: $white;
      padding: 0.25rem 0.313rem;
      cursor: pointer;

      @include border-radius(1.75rem, 1.75rem, 1.75rem, 1.75rem);


      img {
        margin-left: 0.313rem;
      }
    }

    .rules__deagdrops {
      width: 100%;
      margin: 0;
      text-align: center;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.188rem;
      color: $mai-lavender-mist;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .seperator_box {
      position: absolute;
      left: -3.563rem;
      top: 50%;
      transform: translateY(-50%);

      &::after {
        width: 5.125rem;
        height: 0.125rem;
        content: "";
        left: -1.625rem;
        top: 0.813rem;
        position: absolute;
        background-color: $mai-lightblue-shade;
        z-index: -1;
      }

      .rules__and-border {
        background: $mai-ice-castle;
        font-size: 0.5rem;
        line-height: 0.625rem;
        color: $mai-dark-gray;
        padding: 0.188rem 1.125rem;
        text-transform: uppercase;
        z-index: 99;
        top: 0.25rem;
        position: relative;

        @include border-radius(.5rem, .5rem, .5rem, .5rem);
      }
    }
  }

  .accordion-body {
    position: relative;

    .rules__or {
      position: absolute;
      background: $mai-blue-primary-1;
      border: 0.063rem solid rgba(19, 34, 149, 0.1);
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.313rem;
      color: $white;
      padding: 0.188rem 0.688rem;
      left: 0.938rem;
      top: -1.313rem;
      text-transform: uppercase;

      @include border-radius(.25rem, .25rem, .25rem, .25rem);
    }
  }

  .rules__orgroup {
    background: $mai-kodama-white;
    display: inline-block;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    text-align: center;
    color: $mai-blue-primary-1;
    padding: 0.5rem 0.5rem;
    cursor: pointer;

    @include border-radius(.25rem, .25rem, .25rem, .25rem);

    img {
      margin-right: 0.313rem;
    }
  }

  .rules__andorclub {
    border: 0.063rem solid $mai-blue-shde2;
    padding: 0.625rem;
    margin-right: 1rem;
    position: relative;

    @include border-radius(1.125rem, 1.125rem, 1.125rem, 1.125rem);
  }

  .rules__andorwrap {
    position: absolute;
    right: -6.125rem;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0.313rem 0.375rem $mai-grayshade;
    border: 0.063rem solid rgba(19, 34, 149, 0.1);
    line-height: 1.313rem;
    padding: 0.125rem 0;

    @include border-radius(.25rem, .25rem, .25rem, .25rem);

    &::before {
      position: absolute;
      content: "";
      width: 0.625rem;
      height: 0.063rem;
      background-color: $mai-blue-shde2;
      left: -0.625rem;
      top: 50%;
      transform: translateY(-50%);
    }

    span {
      border-right: 0.063rem solid rgba(19, 34, 149, 0.1);
      padding: 0.563rem 0.563rem;
      font-weight: 400;
      font-size: 0.625rem;
      color: $mai-grayshade1;
      cursor: pointer;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.rules__rightwrapper {
  width: calc(100% - 1.25rem);
  float: right;
  margin: 0.625rem 0 0;
  height: 25rem;
  max-height: 25rem;

  .rules__custprofile {
    margin-top: 0.5rem;

    .accordion-item {
      border: none;
      background: $mai-kodama-white;
      margin-bottom: .5rem;

      @include border-radius();

      .accordion-header {
        .accordion-button {
          border: none;
          background: $mai-kodama-white;
          margin-bottom: 0.313rem;
          font-weight: 600;
          padding: 0.75rem 0.938rem;
          color: $mai-dark-gray;
          box-shadow: none;

          @include border-radius();

          &::after {
            background-size: 100%;
            width: 0.75rem;
            height: 0.625rem;
          }
        }
      }

      .accordion-body {
        padding: 0 0.938rem 0.313rem;
        height: 12.188rem;
        overflow-y: scroll;
      }
    }
  }

  .rules__rightsidelist {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: $black;
      background: $white;
      margin-bottom: 0.313rem;
      padding: 0.313rem 0.625rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include border-radius();

      img {
        margin-right: 1.25rem;
      }

      span {
        line-height: 1.125rem;
      }
    }
  }
}

.rules__border-top {
  border-top: 0.063rem solid $mai-white-smoke;
  margin-top: 1.8rem;
}

.subscription-modalpopup {
  .modal-dialog {
    width: 20.625rem;

    .modal-title {
      font-weight: 600;
      font-size: 1.13rem;
      line-height: 1.5rem;
      color: $black;
      opacity: 1;
    }

    select {
      background-color: $mai-gray-primary-3;
      border: 0.063rem solid rgba(19, 34, 149, 0.1);
      height: 2.5rem;

      @include border-radius();

      &:focus {
        box-shadow: none;
      }
    }

    .popup-button {
      background-color: $mai-blue-primary-1;
      border-color: $mai-blue-primary-1;
      color: $white;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.313rem;
      padding: 0.5rem 2.375rem;

      @include border-radius();

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: $mai-blue-primary-1;
        color: $white;
      }
    }

    button {
      &.btn-close {
        color: #000;
        opacity: 1;
        font-size: 16px;
        padding: 0;
        margin: 0;
      }

      &:focus{
        box-shadow: none;
      }
    }
  }
}

.annualsales {
  .modal-dialog {
    width: 25.938rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: $black;
      margin-bottom: 0.313rem;
    }

    input {
      background-color: $mai-gray-primary-3;
      border: 0.063rem solid rgba(19, 34, 149, 0.1);
      @include border-radius();
      height: 2.5rem;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.rulesCalendar {
  margin: 0.625rem 0 0;

  .p-calendar {
    border: 0.063rem solid rgba(19, 34, 149, 0.1);
    @include border-radius();
    background: $mai-gray-primary-3;

    .p-inputtext {
      border: none;
      background: transparent;

      &:hover {
        border-color: none;
        background: transparent;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .p-button {
      color: $black;
      background: $mai-gray-primary-3;
      border: none;
      padding: 0;
      border-left: 0.063rem solid rgba(19, 34, 149, 0.1);

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
}

.p-datepicker:not(.p-datepicker-inline) {
  z-index: 9999 !important;
}

/*----Scroll--------*/
.custom-Scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.063rem $white;
  @include border-radius(0, 0, 0, 0);
  background-color: transparent;
}

.custom-Scroll::-webkit-scrollbar {
  width: 0.375rem;
  background-color: transparent;
}

.custom-Scroll::-webkit-scrollbar-thumb {
  @include border-radius(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 0.063rem $white;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(193, 193, 193, 1) 50%, rgba(255, 255, 255, 1) 100%);
}

.p-multiselect-panel {
  z-index: 9999 !important;
  max-width: 25rem;

  .p-multiselect-items-wrapper {

    .p-multiselect-items {

      li {

        &.p-multiselect-item {
          white-space: pre-wrap;
        }
      }
    }
  }
}

.p-multiselect-label-container {
  max-width: 100% !important;
}

.p-multiselect-chip {

  .p-multiselect-label-container {

    .p-multiselect-label {
      white-space: pre-wrap;

      .p-multiselect-token {
        color: $black;
        margin-bottom: 0.313rem;
        font-size: 0.875rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1.063rem;
        border-radius: .32rem;

        .p-multiselect-token-label {
          width: 87%;
        }

        .p-multiselect-token-icon {
          width: 13%;
        }
      }
    }
  }
}

.p-datatable-emptymessage {
  td {
    max-width: 100% !important;
  }
}

.audiencelistmultiselect {
  position: relative !important;

  .p-multiselect {
    background: $mai-gray-primary-3;
    border: 0.063rem solid rgba(19, 34, 149, 0.1);
    @include border-radius();
    align-items: flex-start;
    position: relative;
    top: 0;
    right: 0;
    width: 100%;

    .p-multiselect-label-container {
      width: 85%;

      .p-multiselect-label {
        padding: 0.438rem 0.25rem;
        max-height: 18.75rem;
        overflow-y: scroll;
      }

      .p-multiselect-label::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 .063rem $white;
        @include border-radius(0, 0, 0, 0);
        background-color: transparent;
      }

      .p-multiselect-label::-webkit-scrollbar {
        width: .35rem;
        background-color: transparent;
        height: .32rem;
      }

      .p-multiselect-label::-webkit-scrollbar-thumb {
        @include border-radius();
        background: $mai-gray-shade6;
        min-height: 6.25rem;
      }
    }

    .p-multiselect-trigger {
      padding: 0.58rem 0;
      width: 15%;
    }
  }
}

.invalid-variable {
  border: .07rem solid $mai-pink-shade;
}

.mai-input {
  background-color: $mai-gray-primary-3;
  border: 0.063rem solid rgba(19, 34, 149, .1);
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
  color: $black;
  font-size: .875rem;
  font-weight: 500;
  width: 100%;

  &:not(.p-disabled) {
    &:hover {
      border: 0.1rem solid rgba(19, 34, 149, .1);
    }

    &.p-focus {
      box-shadow: none;
      border: 0.1rem solid rgba(19, 34, 149, .1);
    }
  }

  .p-dropdown-label {
    padding: .32rem .63rem;
    font-weight: normal;
    color: $black;

    &.p-placeholder {
      color: $black;
      opacity: 0.5;
    }
  }

  .pi-chevron-down {
    font-size: .82rem;
    color: $black;
  }

  .p-dropdown-trigger {
    width: 2rem;
  }

}

.filter-multiselect-input {
  &.p-multiselect {
    &:not(.p-disabled):hover {
      border-color: rgba(19, 34, 149, 0.1);
    }

    &.p-focus {
      box-shadow: none;
      border: 0.1rem solid rgba(19, 34, 149, .1);
    }

    .p-multiselect-label {
      color: $mai-darkgray-shade1;
      font-size: 1rem;
    }
  }

  .p-multiselect-trigger-icon {
    font-size: .75rem;
    color: $black;
    margin: .19rem 0 0;
  }

}

.p-multiselect-panel {
  &.selectoperators {

    .p-multiselect-header {
      padding: .5rem;
      background: $white;
      border-bottom: none;
    }

    .p-multiselect-items-wrapper {
      overflow: scroll;
      max-height: 12.5rem;

      .p-multiselect-items {
        .p-multiselect-item {
          background: $white;
          color: $black;
          line-height: .94rem;
          padding: .32rem .5rem;

          &:not(.p-highlight):not(.p-disabled):hover {
            color: $black;
            background: $white;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 .063rem $white;
        @include border-radius(0, 0, 0, 0);
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        width: .35rem;
        background-color: transparent;
        height: .32rem;
      }

      &::-webkit-scrollbar-thumb {
        @include border-radius();
        background: $mai-gray-shade6;
        min-height: 6.25rem;
      }
    }

    .p-checkbox {
      width: 1.13rem;
      height: 1.13rem;

      .p-checkbox-box {
        border: .13rem solid $mai-gray-shade;
        background: $mai-gray-shade;
        width: 1.13rem;
        height: 1.13rem;
        border-radius: .13rem;

        &.p-highlight {
          border-color: $mai-blue-primary-1;
          background: $mai-blue-primary-1;
        }
      }

      &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
          &.p-focus {
            box-shadow: none;
          }
        }
      }
    }

    .p-multiselect-close {
      color: $black;

      &:focus {
        box-shadow: none;
      }

      .p-multiselect-close-icon {
        font-weight: 600;
      }

      &:enabled {
        &:hover {
          background: transparent;
        }
      }
    }




  }
}