@import "../../../../../../assets/styles/styles.scss";

.createcampaign-or {
    width: 30%;
    position: relative;
    text-align: center;
    // margin-top: 1.57rem;

    span {
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.313rem;
        color: $mai-gray-primary-2;
        background: $white;
        z-index: 999;
        position: relative;
        padding: 0 .375rem;
    }

    &::after {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: .07rem;
        background-color: $mai-grayshade4;
        z-index: 9;

    }
}

.campaignbutton {
    width: 50%;
    // margin-top: 1.57rem;
    margin-left: .75rem;

    .createbutton {
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.313rem;
        color: $mai-blue-primary-1;
        background: $mai-kodama-white;
        display: inline-block;
        text-decoration: none;
        padding: .438rem .375rem;

        @include border-radius();

        img {
            margin-right: .313rem;
        }

    }
}

.campaigngeneraldetails-wrapper {

    .audiencecreate__form {

        .mai-textarea {
            min-height: 8rem;
        }

        .p-dropdown {
            .p-dropdown-label {
                &.p-inputtext {
                    color: $black;
                }

                &.p-placeholder {
                    color: $mai-grayshade4;
                }
            }
        }
    }
}

.chooseaudience_input {
    input {
        width: 100%;
        border: none;
        background-color: transparent;
        color: $black;
        font-size: .875rem;
        font-weight: 400;
        height: 2.188rem;
    }
}

.defineCalendar {
    display: inline-block;
    margin-left: .313rem;

    .p-inputwrapper {
        @include border-radius(.25rem, .25rem, .25rem, .25rem);

        input {
            background-color: $mai-gray-primary-3;
            border: 0.063rem solid rgba(19, 34, 149, 0.1);
            color: $black;
            font-size: .875rem;
            font-weight: 500;
            height: 2.188rem;

            @include border-radius(.25rem, .25rem, .25rem, .25rem);

            &.p-inputtext {
                &:enabled {
                    &:hover {
                        border-color: rgba(19, 34, 149, 0.1);
                    }
                }
            }
        }
    }


}

.fadedTitle {
    @include optionaltitle;
}

.title16 {
    @include titleFonts16;
}

.mai-input {
    @include inputfield;
}