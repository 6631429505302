@import '../../../../../../assets/styles/styles.scss';

.gendetailswrap {
    .audiencecreate__form {

        .mai-input {

            .p-dropdown-label {
                color: $black;
            }

            .p-placeholder {
                color: $mai-grayshade4;
            }
        }

        .mai-textarea {
            min-height: 5rem;
        }
    }
}