@import '../../../../../../assets/styles/styles.scss';

.abtestdetailswrap {
    .productadoptbox {
        background: $mai-light-gray;
        border: .07rem solid $mai-blue-primary-4;
        @include border-radius();
        min-height: 6.57rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            font-weight: 400;
            font-size: .75rem;
            line-height: .75rem;
            text-align: center;
            color: $mai-gray-primary-7;
            margin: .5rem 0 .2rem;
        }

        h3 {
            font-weight: 800;
            font-size: 1rem;
            line-height: 1rem;
            color: $black;
            margin: 0;
        }
    }

    .stakeholderbox {
        background: $mai-gray-primary-3;
        border: .07rem solid rgba(19, 34, 149, 0.1);
        @include border-radius();
        min-height: 5.32rem;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.57rem;

        h2 {
            @include titleFonts18;
            margin: 0 0 .63rem;
        }

        p {
            font-weight: 400;
            font-size: .88rem;
            line-height: 1.07rem;
            color: $black;
            margin: 0;
        }
    }

    .adapthistorybox {
        margin: 1.88rem 0 0;

        .adapthistorybox__inner {
            position: relative;
            padding: 0 0 2.19rem 1.88rem;

            &::before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                width: .07rem;
                height: 100%;
                background: $mai-light-grayshade;
            }

            &::after {
                position: absolute;
                content: '';
                width: 2.13rem;
                height: 2.13rem;
                left: -1.07rem;
                top: 0;
                background-repeat: no-repeat;
                background-position: top left;
                background-size: cover;
            }

            h4 {
                @include titleFonts16;
                margin: 0;
            }

            p {
                @include normaltitleblack;
                margin: 0;
            }

            &.adapthistory-desc {
                &::after {
                    background-image: url('../../../../../../assets/images/desc-icon.png');
                }
            }

            &.adapthistory-hypothesis {
                &::after {
                    background-image: url('../../../../../../assets/images/hypothesis-icon.png');
                }
            }

            &.adapthistory-result {
                &::after {
                    background-image: url('../../../../../../assets/images/result-icon.png');
                }
            }

            &.adapthistory-learning {
                &::after {
                    background-image: url('../../../../../../assets/images/learning-outcome-icon.png');
                }
            }
        }
    }
}