@import "../../../../../../assets/styles/styles.scss";

.summary-column {
    background: $mai-aliceblue-shade;
    padding: 1rem;
    width: 100%;
    min-height: 8.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.875rem 0 0;

    @include border-radius();

    .optimalnumber {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        img {
            width: 1.375rem;
            margin-right: .313rem;
        }

        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.75rem;
            color: $black;

        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.375rem;
            color: $mai-cool-gray;
            margin: 0;
        }
    }

    .numbersummary {
        width: 48%;

        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            line-height: 2rem;
            color: $mai-blue-primary-1;
        }

        a {
            font-style: normal;
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.375rem;
            color: $mai-blue-primary-1;
            text-decoration: none;
        }

        p {
            color: $mai-cool-gray;
            font-style: italic;
            margin: .625rem 0 0;
            font-size: .813rem;
            line-height: .938rem;
        }
    }

}

#recommendationpopup {

    .modal-dialog {
        min-width: 41.875rem;
    }

    .modal-content {
        min-height: 21.875rem;
    }

    .modal-chart {
        min-width: 41.875rem;
    }
}

#showsegmentation {

    h1 {
        &.modal-title {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.375rem;
        }
    }

    button {
        width: auto;

        &.mai-cancel-button {
            background: transparent;
            color: $mai-blue-primary-1;

            &:hover {
                background: transparent !important;
            }
        }

    }
}

.smallpopup {

    h1 {
        &.modal-title {
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.375rem;
        }
    }

    button {
        padding: .313rem 1.563rem;

        &.mai-cancel-button {
            background: transparent;
            color: $mai-blue-primary-1;

            &:hover {
                background: transparent !important;
            }
        }
    }
}

.segment-button {

    a {
        background: $mai-kodama-white;
        border: none;

        @include border-radius();
    }
}

.segmentation-segment-table {

    .p-datatable {

        .p-datatable-wrapper {

            .p-datatable-table {

                thead {

                    th {

                        .p-column-header-content {
                            text-align: left;
                            justify-content: flex-start;
                        }

                        &:first-child {
                            max-width: 20%;
                        }

                        &:nth-child(2) {
                            max-width: 12%;
                        }

                        &:nth-child(3) {
                            max-width: 12% !important;
                        }

                        &:nth-child(4) {
                            max-width: 16%;
                        }

                        &:nth-child(5) {
                            max-width: 13%;
                        }

                        &:nth-child(6) {
                            max-width: 13%;
                        }

                        &:last-child {
                            max-width: 13% !important;
                            justify-content: flex-start;
                        }
                    }
                }

                tbody {

                    tr {
                        &.p-datatable-emptymessage {
                            td {
                                max-width: 100% !important;
                                border: none;
                            }
                        }
                    }

                    td {
                        white-space: pre-wrap;
                        justify-content: flex-start;

                        &:first-child {
                            max-width: 20%;
                        }

                        &:nth-child(2) {
                            max-width: 12%;
                            font-weight: 500;

                            span {
                                &.text-muted {
                                    font-weight: 400;
                                    color: $mai-cool-gray;
                                    margin-left: .313rem;
                                }
                            }
                        }

                        &:nth-child(3) {
                            max-width: 12% !important;
                            font-weight: 500;

                            span {
                                &.text-muted {
                                    font-weight: 400;
                                    color: $mai-cool-gray;
                                    margin-left: .313rem;
                                }
                            }
                        }

                        &:nth-child(4) {
                            max-width: 16%;
                        }

                        &:nth-child(5) {
                            max-width: 13%;
                        }

                        &:nth-child(6) {
                            max-width: 13%;
                        }

                        &:last-child {
                            max-width: 13% !important;
                            justify-content: flex-start !important;
                        }

                    }
                }
            }
        }
    }
}

.segmentlist-datatable {
    thead {
        th {
            &:first-child {
                opacity: 1;
            }
        }
    }

    .p-checkbox {
        width: 1.13rem;
        height: 1.13rem;

        .p-checkbox-box {
            border: .13rem solid $mai-light-red;
            background: $mai-gray-shade;
            width: 1.13rem;
            height: 1.13rem;
            border-radius: .13rem;

            &:hover {
                border-color: $mai-light-red;
            }

            &.p-highlight {
                border: .13rem solid $mai-blue-primary-1 !important;
                background: $mai-blue-primary-1;

                &:hover {
                    background: $mai-blue-primary-1;
                }
            }

        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                &.p-focus {
                    box-shadow: none;
                    border-color: $mai-light-red;
                }
            }
        }
    }
}

.segmentation-summaryTable {
    .p-datatable-wrapper {
        max-height: 27.8rem !important;
        min-height: auto;
    }
}