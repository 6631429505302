@import '../../../../../assets/styles/styles.scss';

.convsratewrapp {
    min-height: 14.7rem;

    .titlefonts18 {
        @include titleFonts18;
    }

    .convsratewrapp__noofmails {
        position: relative;
        text-align: center;
        width: 100%;

        .convsratewrapp__noofmails-totalmails {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: $white;
            width: 6.25rem;
            height: 6.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include border-radius(50%, 50%, 50%, 50%);

            h4 {
                font-weight: 700;
                font-size: 1.5rem;
                line-height: 1.5rem;
                text-align: center;
                color: $mai-eerie-black;
                margin: 0;
            }

            p {
                font-weight: 400;
                font-size: .88rem;
                line-height: 1.4rem;
                text-align: center;
                color: $mai-cool-gray ;
                margin: 0;
            }
        }
    }

    h2 {
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 2rem;
        color: $mai-eerie-black;
        margin: 0;
    }

    p {
        font-weight: 400;
        font-size: .88rem;
        line-height: 1.13rem;
        color: $mai-cool-gray ;
        margin: 0;
    }
}

.campmonitoringsummwrapp {
    min-height: 14.7rem;

    .campmonitoringsummwrapp__box {

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1rem;
        min-height: 5.75rem;

        &.greenshadebox {
            background: rgba(239, 249, 245, 0.5);
            border: .06rem solid rgba(53, 180, 126, 0.35);
        }

        &.redshadebox {
            background: rgba(254, 240, 242, 0.5);
            border: .06rem solid rgba(236, 74, 68, 0.35);
        }

        &.blueshadebox {
            background: rgba(235, 238, 247, 0.5);
            border: .06rem solid rgba(2, 47, 158, 0.3);
        }

        @include border-radius();

        img {
            margin-right: .63rem;
        }

        h4 {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.5rem;
            color: $mai-eerie-black;
            margin-bottom: .32rem;
        }

        p {
            font-weight: 400;
            font-size: .88rem;
            line-height: 1.1rem;
            color: $mai-cool-gray ;
            margin: 0;
        }
    }

    .campmonitoringsummwrapp-summarydesc {

        h5 {
            font-weight: 600;
            font-size: 1.13rem;
            line-height: 1.13rem;
            color: $mai-dark-gray ;
            margin: 0;
        }

        p {
            font-weight: 400;
            font-size: .88rem;
            line-height: 1.31rem;
            text-align: justify;
            color: $black;
            margin: 0;
        }
    }
}

.emailerwrap {

    .emailerwrap__heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        cursor: pointer;
        padding: 0 1rem;

        img {
            margin-right: .5rem;
        }

        p {
            font-weight: 400;
            font-size: .75rem;
            line-height: .88rem;
            color: $black;
            margin: 0;
        }

        h3 {
            font-weight: 600;
            font-size: 1.13rem;
            line-height: 1.25rem;
            color: $black;
            margin: 0;
        }
    }

    .emailerwrap__list {
        padding: 0 1rem;

        ul {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 0;

            li {
                width: 20%;
                border-left: .06rem solid $mai-blueshade6;
                padding: .32rem 0 .32rem .94rem;
                min-height: 4.4rem;

                &:first-child {
                    border-left: none;
                    padding-left: 0;
                }

                h4 {
                    font-weight: 600;
                    font-size: 1.4rem;
                    line-height: 1.4rem;
                    color: $mai-eerie-black;
                    margin: 0;
                }

                p {
                    font-weight: 400;
                    font-size: .88rem;
                    line-height: .94rem;
                    color: $mai-cool-gray ;
                    margin-top: .32rem;
                }
            }
        }
    }

    .emailerwrap__hrborder {
        width: 100%;
        height: .06rem;
        background: $mai-alice-blue;
        margin: 1.6rem 0;
    }
}

.trendswrapper,
.segmentswrapper {

    .inner__heading {

        span {
            font-weight: 400;
            font-size: .75rem;
            line-height: .88rem;
            color: $black;
            opacity: 0.5;
        }
    }

    .indicatorlist {
        @include chartindicator;
    }
}

.dot3-dropdownwrap {

    .dropdown-toggle {

        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        box-shadow: rgb(195 195 195) 0 0.3rem 0.3rem;
        border: none;
        padding: 0.438rem 0;
        width: 12.5rem;
        border-radius: 0.25rem;
        @include border-radius();

        li {

            a {
                border: none;
                text-align: left;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                color: $black;
                padding: 0.313rem 0.938rem;

                &:hover {
                    background-color: transparent;
                }

                img {
                    margin-right: 0.313rem;
                }
            }
        }
    }

    &.rightaligndropdown {
        .dropdown-menu {
            transform: translate(0, 1.6rem) !important;
            right: 0 !important;
        }
    }
}

.segmentswrapper {
    min-height: 20.62rem;
}

.selectlist-wrap .selectlist .p-dropdown-label {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    color: $black;

    &.p-placeholder {
        color: $mai-blue-shade2;
    }
}

.contactrolewrap {

    .contactrolewrap__desc {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        .contactrolewrap__desctext {
            width: 33%;
            border-right: .06rem solid $mai-gray-shade1;
            border-bottom: .06rem solid $mai-gray-shade1;
            padding: .625rem;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
            }

            h3 {
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 2rem;
                color: $black;
                margin: 0;
            }

            p {
                font-weight: 600;
                font-size: .82rem;
                line-height: 1rem;
                color: $black;
            }

            img {
                min-height: 3.2rem;
            }
        }

        &.bb-0 {

            .contactrolewrap__desctext {
                border-bottom: none;
            }
        }
    }

}

.campaignEmailPopup {

    .modal-dialog {
        max-width: 34.4rem;
        min-height: 37.5rem;

        .modal-body {
            padding-right: 0;
            padding-left: 0;

            .emailpopupform {
                label {
                    margin-bottom: 1rem;
                }

                .p-calendar {
                    position: relative;

                    &::before {
                        position: absolute;
                        content: '';
                        right: .63rem;
                        top: 50%;
                        transform: translateY(-50%);
                        width: .75rem;
                        height: .82rem;
                        background-image: url('../../../../../assets/images/calendar-grey-icon.svg');
                    }

                    input {
                        background: $mai-gray-primary-3;
                        border: .06rem solid rgba(19, 34, 149, 0.1);
                        @include border-radius();
                        color: $black;
                        font-size: 1rem;
                        padding: .5rem .63rem;
                        height: 2.5rem;

                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }

                        &::placeholder {
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 1.13rem;
                            color: $mai-gray-primary-2;
                        }
                    }

                }


                textarea {
                    background: $mai-gray-primary-3;
                    border: .06rem solid rgba(19, 34, 149, 0.1);
                    @include border-radius();
                    color: $black;
                    font-size: 1rem;
                    padding: .5rem .63rem;
                    height: 7.5rem;
                    resize: none;

                    &:focus {
                        box-shadow: none;
                        outline: none;
                    }

                    &::placeholder {
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1.13rem;
                        color: $mai-gray-primary-2;
                    }
                }

            }
        }
    }

    .tabswrap {

        .p-tabview {

            .p-tabview-nav-container {

                .p-tabview-nav-content {

                    .p-tabview-nav {
                        border-bottom: .06rem solid $mai-white-smoke;

                        li {
                            &.p-unselectable-text {
                                border: none;
                                margin-right: 1rem;
                            }

                            &.p-tabview-ink-bar {
                                @include border-radius();
                                background: $mai-blue-shade2;
                                height: .25rem;

                            }
                        }
                    }
                }
            }
        }
    }
}

.campaignmonitoringdetailswrap {

    .p-tabview {
        .p-tabview-panels {
            padding: 1rem 0;
        }
    }

    .campaignmonitorintabs-heading {
        background: $white;
        padding: 1rem;
        border-bottom: 0.063rem solid $mai-white-smoke;
        @include border-radius(.32rem, .32rem, 0, 0);

        h3 {
            @include titleFonts18;
            margin: .5rem 0;

            span {
                font-weight: 700;
                font-size: .75rem;
                line-height: .88rem;
                display: inline-block;
                color: $mai-green-shade3;
                padding: .13rem .44rem;

                background: rgba(28, 154, 78, 0.1);
                @include border-radius();
            }
        }
    }

}

.daterangewrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    h4 {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.2rem;
        color: $mai-gray-primary-7;
        margin-right: .5rem;
    }

    .p-calendar {
        padding-right: .75rem;

        &::before {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: .63rem;
            height: .38rem;
            background: url('../../../../../assets/images/arrow-down-blue.svg');
        }

        .p-inputtext {
            padding: 0;
            border: none;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.31rem;
            color: $mai-blue-shade2;

            &::placeholder {
                color: $mai-blue-shade2;
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }
        }
    }

}

.selectedemailwrap {
    background: rgb(237 242 246 / 32%);
    position: relative;
    padding: 1rem;

    &::before {
        width: .25rem;
        height: 100%;
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        background: $mai-blue-primary-4;
    }

}

.campaignmonitorintabs-heading .status-badge {
    padding: 0.25em 0.5rem;
    font-weight: 700;
    font-size: 0.75rem;
    letter-spacing: 0.019rem;

    @include border-radius(.125rem, .125rem, .125rem, .125rem);

    &.status-pre-campaign {
        background: $mai-blueshade5;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: $mai-blue-shade2;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);
    }

    &.status-on-going {
        background: $mai-greenshade7;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        text-align: center;
        color: $mai-green-shade3;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);

    }

    &.status-post-campaign {
        background: $mai-orange-shade2;
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        text-align: center;
        color: $mai-orangeshade2;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);
    }
}