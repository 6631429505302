@import '../../../../../assets/styles/styles.scss';

%steps {
    position: absolute;
    top: -0.125rem;
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    background-color: $mai-stone-color;

    @include border-radius(50%, 50%, 50%, 50%);
}

.audiencecreate__steps {
    padding: 0 1.5rem;
    border-top: 0.063rem solid $mai-white-smoke ;

    &.sticky-top {
        z-index: 9;
    }

    ul {
        margin: 0;
        display: flex;
        align-items: center;

        li {
            color: $mai-stone-color;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.375rem;
            padding: 0.438rem 1.25rem;
            margin: 0 !important;

            &.audiencecreate__steps--active {
                color: $mai-blue-primary-1;
            }

            &.audiencecreate__steps--line {
                width: 5.875rem;
                height: 0.125rem;
                padding: 0;
                background: $mai-stone-color;
                position: relative;

                &.steps__active {
                    &::before {
                        @extend %steps;
                        left: 0;
                        background-color: $mai-blue-primary-1;
                    }
                }

                &::before {
                    @extend %steps;
                    left: 0;
                }

                &::after {
                    @extend %steps;
                    right: 0;
                }
            }

            &:first-child {
                padding-left: 0;
            }
        }
    }
}

.audiencecreate__form-wrapper {
    background-color: $mai-alice-blue;
    padding: 1rem;

    .audiencecreate__form {
        background: $white;
        border: 0.063rem solid $mai-white-smoke ;
        padding: 1rem;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);

        .p-autocomplete-multiple {
            ul {
                li {
                    color: $black;
                    margin: 0.25rem 0.313rem 0.188rem 0;
                }
            }
        }

        .titles {
            font-style: normal;
            font-weight: 700;
            font-size: 1.3rem;
            line-height: 1.813rem;
            color: $black;
        }

        .mai-input {
            background-color: $mai-gray-primary-3;
            border: 0.063rem solid rgba(19, 34, 149, 0.1);
            color: $black;
            font-size: 0.875rem;
            font-weight: 500;

            @include border-radius(.25rem, .25rem, .25rem, .25rem);
        }

        .mai-form-label {
            color: $mai-dark-gray;
            font-size: 0.938rem;
            font-weight: 600;
            line-height: 1.125rem;
            margin-bottom: 0.313rem;
        }

        .mai-textarea {
            background: $mai-gray-primary-3;
            border: 0.063rem solid rgba(19, 34, 149, 0.1);
            min-height: 8.125rem;
            color: $black;
            font-size: 0.875rem;
            font-weight: 500;

            @include border-radius(.25rem, .25rem, .25rem, .25rem);
        }

        .mai-label-access {
            color: $mai-dark-gray;
            font-size: 0.938rem;
            font-weight: 600;
            line-height: 1.125rem;
            margin-right: 1.25rem;
        }

        .mai-radio-button {
            background-color: $mai-stone-color;
            border: none;
            width: 0.563rem;
            height: 0.563rem;
            opacity: 0.4;
            margin-top: 0.438rem;
            margin-left: -1rem;

            &:checked {
                background-color: $mai-blue-primary-4;
                border-color: $mai-blue-primary-4;
                opacity: 1;
                background-image: none;
            }
        }

        .mai-radio-label {
            line-height: 1.125rem;
            font-size: 0.938rem;
            color: $mai-dark-gray;
        }

        .mai-label-sharewith {
            color: $mai-dark-gray;
            font-size: 0.938rem;
            font-weight: 600;
            line-height: 1.125rem;

            span {
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.063rem;
                color: $mai-gray-primary-7;
                margin-left: 0.313rem;
            }
        }

        .p-inputwrapper {
            padding: 0;

            ul {
                background: $mai-gray-primary-3;
                color: $black;
                font-size: 0.875rem;
                font-weight: 500;
                padding: 0 0.75rem;
                border: none;

                &.p-focus {
                    box-shadow: none;
                }

                &:hover {
                    border-color: rgba(19, 34, 149, 0.1);
                }

                &:focus {
                    box-shadow: none;
                }

                li {

                    input {
                        border: none;
                        font-size: 0.875rem;
                    }
                }
            }
        }

        ::placeholder {
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $mai-gray-primary-2;
        }

        :focus {
            box-shadow: none;
        }
    }
}

button.btn:disabled {
    @include disableBtn;
}

button.mai-cancel-button {
    @include cancelButton;
}

button.mai-update-button {
    @include updateButton;
}

button.mai-next-button {
    @include nextButton;
}

.rules__searchbox {
    @include searchbox;
}

.boxmin-height {
    min-height: 25.63rem;
}