@import '../../../../../../assets/styles/styles.scss';

.campaignoptimization-summarywrap {
    .clientscohort {
        padding: 0 1.5rem;

        h1 {
            @include titleFonts18;
            margin: 0;

            img {
                margin: .13rem .44rem 0 0;
                float: left;
            }
        }

        h2 {
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 2rem;
            color: $black;
            margin: 0;

            span {
                font-weight: 800;
                color: $mai-blue-shade2;
            }
        }

        .cohort-perWrap {
            width: 100%;
            float: left;
            background: $mai-blue-shade3;
            @include border-radius();


            .cohort-perInner {
                width: 20%;
                height: 2.25rem;
                background: $mai-blue-primary-4;
                font-weight: 800;
                font-size: 1rem;
                line-height: 2.19rem;
                color: $white;
                @include border-radius();
                margin: -.19rem auto;
            }
        }

        .cohortSummary {
            width: 90%;
            margin: 0 auto -.06rem;
            background: $mai-alice-blue;
            box-shadow: 0 -.13rem .13rem rgb(0 0 0 / 15%);
            font-weight: 700;
            font-size: .88rem;
            line-height: 1.13rem;
            color: $mai-cool-gray;
            padding: .32rem;
            @include border-radius(.32rem, .32rem, 0, 0);
        }
    }
}

.summaryWrapDesc {
    background-color: $mai-alice-blue;
    padding: 1rem;
    border-top: .06rem solid $mai-stone-color;
}