@import '../../../../../../assets/styles/styles.scss';

.testresult {
    min-height: 11.57rem;

    h2 {
        @include titleFonts18;
        margin: 0;
    }

    p {
        margin: 0;
        font-weight: 500;
        @include normaltitleblack;


        span {
            color: $mai-green-shade;
            font-weight: 600;
            font-size: 1rem;
        }
    }
}

.subjectlinetabs {

    .p-tabview {
        .p-tabview-panels {
            padding: 1.25rem 0 0;
        }

        .p-tabview-nav {
            border: .07rem solid $mai-white-smoke;
            border-width: 0 0 .07rem 0;

            li {

                &.p-unselectable-text {
                    border: .07rem solid $mai-white-smoke;
                    margin-right: .63rem;
                    @include border-radius(.32rem, .32rem, 0, 0);

                    .p-tabview-nav-link {
                        border: .07rem solid $mai-white-smoke;
                        border-width: 0 0 .07rem 0;
                        margin: 0 0 -.07rem;
                        padding: .63rem 1.13rem;

                        background: $white;
                        @include border-radius(.32rem, .32rem, 0, 0);

                        font-weight: 400;
                        font-size: .94rem;
                        line-height: 1.19rem;
                        color: $mai-gray-primary-7;

                        &:focus {
                            box-shadow: none;
                        }

                        &:hover {
                            border-bottom: none;
                        }
                    }
                }

                &.p-highlight {
                    .p-tabview-nav-link {
                        background: $mai-white-shade;
                        color: $mai-blue-shade2;
                        font-weight: 700;
                        font-size: .94rem;
                    }
                }

                &.p-tabview-ink-bar {
                    margin-right: 0;
                    height: .25rem;
                    background-color: $mai-blue-shade2;
                }
            }
        }
    }
}

.table {
    &.subjectlinetable {
        margin-bottom: 0;

        th {
            border: .07rem solid $mai-white-smoke;
            font-weight: 700;
            text-transform: uppercase;
            font-size: .75rem;
            line-height: 1.32rem;
            color: rgb(0 0 0 / 50%);
            padding: .5rem 0;
            text-align: center;
            width: 50%;
        }

        td {
            background: $mai-gray-primary-3;
            border: .07rem solid rgba(19, 34, 149, 0.1);
            @include border-radius(0, 0, .25rem, .25rem);
            font-weight: 500;
            font-size: .88rem;
            line-height: 1.13rem;
            color: $black;
            padding: 1.13rem .63rem .94rem;
            width: 50%;
        }
    }

    &.openratetable {
        margin-bottom: 0;

        td {
            padding: 0;

            table {
                margin: 0;

                &.varianta-table {
                    th {
                        background: rgb(200 241 215 / 20%);
                        color: rgb(0 0 0 / 50%);
                        border: none;
                        padding: .44rem .63rem;
                        text-align: left;
                        text-transform: capitalize;
                    }

                    td {
                        background: $mai-green-shade;
                        border: none;
                        color: $white;
                        @include border-radius(0, 0, 0, 0);
                        padding: 1.07rem .94rem;
                    }
                }

                &.variantb-table {
                    th {
                        background: rgb(236 74 68 / 4%);
                        color: rgb(0 0 0 / 50%);
                        border: none;
                        padding: .44rem 0;
                        text-align: left;
                        padding: .44rem .63rem;
                        text-transform: capitalize;
                    }

                    td {
                        background: rgb(236 74 68 / 8%);
                        border: none;
                        color: $black;
                        @include border-radius(0, 0, 0, 0);
                        padding: 1.07rem .94rem;
                    }
                }
            }
        }
    }
}