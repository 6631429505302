@import '../../../../../assets/styles/styles.scss';

.optimizationPopup {

    .modal-dialog {
        max-width: 28.13rem;

        .form-check {
            .mai-radio-button {
                @include radiobutton;
            }

            .mai-radio-label {
                @include radioLabel;
            }
        }
    }

}