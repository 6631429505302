* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

.p-calendar {
    @include calendaricon;
}

.mai-input {
    &:focus {
        box-shadow: none;
        outline: none;
        border: .063rem solid $mai-cyan-blue-light;
    }
}

.box-shadow {
    background-color: $white;
    @include border-radius(.5rem, .5rem, .5rem, .5rem);
    box-shadow: 0 0.25rem 0.5rem $mai-light-silver;
    padding: 1rem;
}

/*----Scroll--------*/
.custom-Scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 .063rem $white;
    @include border-radius(0, 0, 0, 0);
    background-color: transparent;
}

.custom-Scroll::-webkit-scrollbar {
    width: .35rem;
    background-color: transparent;
    height: 5px;
}

.custom-Scroll::-webkit-scrollbar-thumb {
    @include border-radius();
    background: $mai-gray-shade6;
    min-height: 100px;
}

.p-paginator {
    margin: 1rem 0 0;
    padding: 0;
}

.p-paginator-pages {
    .p-paginator-page {
        min-width: 1rem;
        height: 1rem;
        margin-top: 0;
        margin-bottom: 0;
        color: $black;
        font-size: 1rem;
    }
}

.hrline {
    border-color: $mai-chinese-silver;
}

.normalpara {
    font-weight: 400;
    font-size: .88rem;
    line-height: 1.32rem;
    color: $mai-dark-gray-shade;
    margin: 0;
}

.normalinputfield {
    height: 2.19rem;
    background: $white;
    border: .07rem solid rgba(19, 34, 149, 0.1);
    @include border-radius();

    &:focus {
        box-shadow: none;
        border: .07rem solid rgba(19, 34, 149, 0.1);
    }

}


.customcheckwrap {
    display: inline-block;
    background-color: $mai-gray-primary-3;
    border: 0.063rem solid rgba(19, 34, 149, 0.1);
    color: $black;
    font-size: 0.875rem;
    font-weight: 500;
    @include border-radius();

    .form-check {
        min-height: 2.19rem;
        padding: 0;
        margin: 0;

        &:first-child {
            border-right: 0.063rem solid rgba(19, 34, 149, 0.1);
        }

        input[type="radio"]:checked,
        input[type="radio"]:not(:checked) {
            position: absolute;
            left: -624.94rem;
        }

        input[type="radio"]+label {
            position: relative;
            padding: .44rem .63rem .44rem 1.57rem;
            cursor: pointer;
            line-height: 1.25rem;
            display: inline-block;
        }

        input[type="radio"]:checked+label {
            color: $black;
        }

        input[type="radio"]:not(:checked)+label {
            color: $mai-gray-primary-2;
        }

        input[type="radio"]:checked+label:before {
            content: '';
            position: absolute;
            left: .44rem;
            top: .69rem;
            width: .88rem;
            height: .88rem;
            border: .13rem solid $mai-blue-primary-4;
            @include border-radius(50%, 50%, 50%, 50%);
            background: $white;
        }

        input[type="radio"]:not(:checked)+label:before {
            content: '';
            position: absolute;
            left: .44rem;
            top: .69rem;
            width: .88rem;
            height: .88rem;
            border: .13rem solid rgba(19, 34, 149, 0.1);
            @include border-radius(50%, 50%, 50%, 50%);
            background: $white;
        }

        input[type="radio"]:checked+label:after,
        input[type="radio"]:not(:checked)+label:after {
            content: '';
            width: .38rem;
            height: .38rem;
            background: $mai-blue-primary-4;
            position: absolute;
            top: .94rem;
            left: .69rem;
            @include border-radius(50%, 50%, 50%, 50%);
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
        }

        input[type="radio"]:not(:checked)+label:after {
            opacity: 0;
            -webkit-transform: scale(0);
            transform: scale(0);
        }

        input[type="radio"]:checked+label:after {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
}

.box-maxheight {
    max-height: 27.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.p-dropdown-panel {
    .p-dropdown-items {
        padding: 0;

        li {
            &.p-dropdown-item {
                &:first-child {
                    @include border-radius(.25rem, .25rem, 0, 0);
                }

                &:last-child {
                    @include border-radius(0, 0, .25rem, .25rem);
                }
            }
        }
    }
}

.tabs-common-wrap {

    .p-tabview {

        .p-tabview-nav-container {

            .p-tabview-nav-content {

                .p-tabview-nav {

                    li {
                        &.p-unselectable-text {
                            margin-right: .44rem;
                            padding-right: .44rem;
                        }
                    }
                }
            }
        }
    }
}

.list-datatable {
    thead {
        th {
            background: $mai-white-shade !important;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 0.75rem;
            line-height: 0.875rem;
            color: $black !important;
            opacity: 0.5;
            border: none !important;
            height: 2.313rem;
            padding: 0 0.438rem !important;
            white-space: pre-wrap;

            &.p-sortable-column {
                &:focus {
                    box-shadow: none;
                }
            }

            // &:first-child {
            //     max-width: 17.5%;
            // }

            // &:nth-child(2) {
            //     max-width: 8.5%;
            // }

            // &:nth-child(3) {
            //     max-width: 18.5% !important;
            // }

            // &:nth-child(4) {
            //     max-width: 10.5%;
            // }

            // &:nth-child(5) {
            //     max-width: 14.5% !important;
            // }

            // &:nth-child(7) {
            //     max-width: 9.5% !important;
            // }

            &:last-child {
                // max-width: 8.5% !important;
                justify-content: center;
            }

            .pi {
                font-size: 0.75rem;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(2n+2) {
                background: $mai-aliceblue-shade;
            }

            &.p-datatable-emptymessage {

                td {
                    max-width: 100% !important;
                    justify-content: flex-start !important;
                }
            }
        }

        td {
            border: none !important;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.063rem;
            color: $black;
            position: relative;
            white-space: pre-wrap !important;
            overflow: visible !important;
            text-overflow: ellipsis;
            text-align: left !important;
            align-items: center !important;
            justify-content: flex-start !important;
            padding: 0.438rem !important;
            min-height: 3rem;

            &:focus {
                outline: none !important;
            }

            &:hover {
                background: transparent !important;
                color: $black;
            }

            &:first-child {
                font-weight: 700;
                // max-width: 17.5%;
            }

            &:nth-child(2) {
                font-weight: 600;
                // max-width: 8.5%;
            }

            &:nth-child(3) {
                // max-width: 18.5% !important;
                font-weight: 400;
            }

            &:nth-child(4) {
                // max-width: 10.5%;
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 0.875rem;
                color: $mai-nero;
                // text-align: center;

                span {
                    .p-avatar-text {
                        font-weight: 400;
                        font-size: 0.75rem;
                    }
                }
            }

            &:nth-child(5) {
                // max-width: 14.5% !important;
            }

            &:nth-child(7) {
                // max-width: 9.5% !important;
            }

            &:last-child {
                // max-width: 8.5% !important;
                justify-content: center !important;
            }

            .pi {
                font-size: 0.75rem;
                cursor: pointer;
            }
        }
    }
}

.pi-sort-alt {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 9px;
        height: 12px;
        top: -5px;
        left: 0;
        background: url('../../assets/images/shorting-icon.svg') no-repeat scroll center center;
        background-size: cover;
        opacity: 0.4;
    }
}

.pi-sort-amount-up-alt {
    position: relative;

    &::before {
        opacity: 0.4;
    }
}

.pi-sort-amount-down {
    position: relative;

    &::before {
        opacity: 0.4;
    }
}

.whitebox-height-with-tabs {
    height: calc(100vh - 8.2rem);
}

.p-multiselect {
    .p-multiselect-trigger {
        .p-multiselect-trigger-icon {
            width: 12px;
        }
    }
}

.p-dropdown {
    .p-dropdown-trigger {
        .p-dropdown-trigger-icon {
            width: 12px;
        }
    }
}
.p-sortable-column{
    .p-column-header-content{
        .p-sortable-column-icon{
            width: 12px;
        }
    }
}