@import '../../../../../../../assets/styles/styles.scss';

.insights__innerwrapper {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem #d7d8dd;
    padding: 0.75rem;
    min-height: 142px;

    .industries-dropdown {

        .dropdown-toggle {

            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            box-shadow: rgb(195 195 195) 0px 0.3rem 0.3rem;
            border: none;
            padding: 0.438rem 0;
            width: 12.5rem;
            border-radius: 0.25rem;
            transform: translate(82px, 25px) !important;

            li {

                a {
                    border: none;
                    text-align: left;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: #000000;
                    padding: 0.313rem 0.938rem;

                    &:hover {
                        background-color: transparent;
                    }

                    img {
                        margin-right: 0.313rem;
                    }
                }
            }
        }
    }

    .inner__heading {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
            margin: 0 0.5rem;

            &:first-child {
                margin-left: 0;

            }
        }
    }

    .innertext__size {
        margin: 0;
        position: relative;

        .increase {
            background: #C8F1D7;
            border-radius: 0.25rem;
            font-weight: 700;
            font-size: 0.75rem;
            line-height: 0.938rem;
            color: #1C9A4E;
            display: inline-block;
            padding: 0.063rem 3px;
            position: absolute;
            top: -1.313rem;
            left: 0;
        }


        h2 {
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.25rem;
            color: $mai-blue-primary-1;

            .increase_horizontal {
                background: #C8F1D7;
                border-radius: 0.25rem;
                font-weight: 700;
                font-size: 0.75rem;
                line-height: 0.938rem;
                color: #1C9A4E;
                display: inline-block;
                padding: 0.063rem 3px;
                margin-left: 0.438rem;
            }

            .decrease_horizontal {
                background: rgb(248 190 188 / 50%);
                border-radius: 0.25rem;
                font-weight: 700;
                font-size: 0.75rem;
                line-height: 0.938rem;
                color: #EC4A44;
                display: inline-block;
                padding: 0.063rem 3px;
                margin-left: 0.438rem;
            }

            sub {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                color: #8993A3;
                ;
            }
        }

        p {
            margin: 0;
            font-weight: 400;
            font-size: .875rem;
            line-height: 1.375rem;
            color: $mai-gray-primary-7;
        }
    }

    .insights-pipeline {
        margin: 0 !important;

        li {
            width: 20% !important;
            padding: 0 !important;
            border: none !important;
            text-align: left;

            &:first-child {
                width: 33% !important;
            }
        }
    }

    .insights-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.25rem 0 0;
        padding: 0;

        li {
            width: 33%;
            border-right: 0.063rem solid #C1C7D0;
            padding: 0 0.32rem 0 0;

            &:first-child {
                width: 37%;
            }

            &:last-child {
                border-right: none;
            }

            h2 {
                color: #212121;
                font-size: 1.125rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                sub {
                    font-size: .82rem;
                    line-height: .82rem;
                    margin-left: 0;
                    bottom: 0;
                }
            }

            p {
                line-height: 1.125rem;
                color: #8993A3;
                font-size: 0.688rem;
            }
        }
    }

    .dots3 {
        color: #212121;
        font-size: 0.813rem;
        cursor: pointer;
    }

    .inner-heading {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.188rem;
        color: #000000;
        margin: 0 0 0.438rem;
    }

    .data-per {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.188rem;
        color: #000000;

        span {
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: #000000;
            opacity: 0.5;
            display: block;
        }
    }

    .data-title {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.063rem;
        text-align: right;
        color: #8993A3;
    }

    .progressbar {
        h4 {
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.188rem;
            color: #000000;
            margin: 0 0 0.25rem;
        }

        .p-progressbar-value {
            background: #00B8D9;
        }

        .p-progressbar {
            height: 0.5rem;
            background: #dee2e6;
        }

        .p-progressbar-label {
            display: none;
        }

    }

    .tabs-wrapper {
        margin: 0;

        .p-tabview-nav-container {
            // display: inline-block;
            float: right;
            margin: -1.5rem 0 0;
            padding-right: 0 !important;

            .p-tabview-nav {
                background: #F3F4F6;
                border: 0.125rem solid #F3F4F6;
                @include border-radius();

                li {

                    &:not(.p-highlight) {

                        &:hover {
                            .p-tabview-nav-link {
                                background: transparent;
                                border-color: transparent;
                                color: #8993A3;
                            }
                        }
                    }

                    &.p-highlight {

                        a {
                            &.p-tabview-nav-link {
                                background: #fff;
                                border-color: #f3f4f6;
                                color: #022F9E;
                                font-weight: 700;
                                @include border-radius();
                            }
                        }
                    }

                    a {

                        &.p-tabview-nav-link {
                            background: transparent;
                            border-color: #f3f4f6;
                            color: #8993A3;
                            font-weight: 600;
                            font-size: 0.875rem;
                            line-height: 1.313rem;
                            padding: 0.438rem 0.938rem;
                            border-radius: 0.375rem;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    &.p-tabview-ink-bar {
                        display: none;
                    }

                }
            }
        }
    }

    .p-tabview-panels {
        padding: 1.25rem 0 0;
    }

    .transactions-idicatorslist {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            margin: 0 1.25rem;
            display: flex;
            align-items: center;

            span {
                width: 1.063rem;
                height: 0.125rem;
                display: inline-block;
                margin-right: 0.313rem;
                background: $black;

                &.products-usage-bar {
                    background: #06B6D4;
                }

                &.monthly-payment-bar {
                    background: #75AA75;
                }

                &.avg-supply-bar {
                    background: #FBBF24;
                }
            }
        }
    }
}

.kpititle {
    white-space: nowrap;
}

.pipelinevalue {
    font-weight: 600 !important;
    color: #000;
    font-size: 1rem !important;
}