@import '../../../../../assets/styles/styles.scss';

.contactstrategylist-datatable {

    thead {
        th {
            background: $mai-white-shade !important;
            font-style: normal;
            font-weight: 700 !important;
            font-size: 0.75rem;
            line-height: 0.875rem;
            color: $black !important;
            opacity: 0.5;
            border: none !important;
            height: 2.313rem;
            padding: 0 0.438rem !important;

            &.p-sortable-column {
                &:focus {
                    box-shadow: none;
                }
            }

            &:last-child {
                justify-content: center;
            }

            .pi {
                font-size: 0.75rem;
            }
        }
    }

    tbody {
        tr {
            &:nth-child(2n+2) {
                background: $mai-aliceblue-shade;
            }

            &.p-datatable-emptymessage {

                td {
                    max-width: 100% !important;
                    justify-content: flex-start !important;
                }
            }
        }

        td {
            border: none !important;
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.063rem;
            color: $black;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left !important;
            align-items: center !important;
            justify-content: flex-start !important;
            padding: 0.438rem !important;
            height: 3rem;

            &:focus {
                outline: none !important;
            }

            &:hover {
                background: transparent !important;
                color: $black;
            }

            &:first-child {
                font-weight: 700;
            }

            &:nth-child(2) {
                font-weight: 600;
            }

            &:nth-child(3) {
                font-weight: 400;
                white-space: pre-wrap;
            }

            &:nth-child(4) {
                font-weight: 500;
                font-size: 0.75rem;
                line-height: 0.875rem;
                color: $mai-nero;
                text-align: center;

                span {
                    .p-avatar-text {
                        font-weight: 400;
                        font-size: 0.75rem;
                    }
                }
            }

            &:last-child {
                justify-content: center !important;
            }

            .pi {
                font-size: 0.75rem;
                cursor: pointer;
            }
        }
    }

    .p-datatable-wrapper {
        max-height: 28rem !important;
    }

    .p-datatable-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0.063rem $white;
        background-color: transparent;

        @include border-radius(0, 0, 0, 0);
    }

    .p-datatable-wrapper::-webkit-scrollbar {
        width: 0.375rem;
        background-color: transparent;
    }

    .p-datatable-wrapper::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 0.063rem $white;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(193, 193, 193, 1) 50%, rgba(255, 255, 255, 1) 100%);

        @include border-radius(0, 0, 0, 0);
    }


}