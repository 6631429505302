@import '../../../../../assets/styles/styles.scss';

.insights-card-wrap {
    padding: .38rem .75rem;

    h5 {
        font-size: 1rem;
        font-weight: 700;
        color: $black;
        line-height: 1.25rem;

        span {
            display: block;
            font-weight: 400;
            font-size: .75rem;
            line-height: 1rem;
            color: $mai-gray-primary-7;
        }
    }
}

.channel_leveldetails-chart {
    background: $mai-gray-shade3;
    padding: .32rem .32rem 1rem;
    min-height: 18.75rem;
    border-radius: .32rem;

    h3 {
        font-size: 1rem;
        font-weight: 700;
        color: $black;
        line-height: 1.25rem;
        text-align: center;
    }
}

.insightspage-calendar {
    &.p-calendar {
        &::before{
            display: none;
        }

        &.p-inputwrapper-focus{
            input{
                outline: none;                
            }
        }
        .p-inputtext {
            width: auto !important;
            padding-right: .32rem;

            &:enabled{
                &:focus{
                    box-shadow: none;
                }
            }
        }
    }
}