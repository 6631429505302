@import "../../../../../../assets/styles/styles.scss";

.createsegment-or {
    width: 50%;
    position: relative;
    text-align: center;
    margin-top: .938rem;

    span {
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.313rem;
        color: $mai-gray-primary-2;
        background: $white;
        z-index: 9999;
        position: relative;
        padding: 0 .375rem;
    }

    &::after {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 74%;
        height: .063rem;
        background-color: $mai-grayshade4;
        z-index: 9;

    }
}

.segmentbutton {
    width: 50%;
    margin-top: .938rem;

    .createbutton {
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.313rem;
        color: $mai-blue-primary-1;
        background: $mai-kodama-white;        
        display: inline-block;
        text-decoration: none;
        padding: .438rem .375rem;

        @include border-radius();

        img {
            margin-right: .313rem;
        }

    }
}

.segmentgeneraldetails-wrapper {

    .audiencecreate__form {

        .mai-textarea {
            min-height: 5rem;
        }
    }
}

.chooseaudience_input {
    input {
        width: 100%;
        border: none;
        background-color: transparent;
        color: $black;
        font-size: .875rem;
        font-weight: 400;
        height: 2.188rem;
    }

}