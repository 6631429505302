@import '../../../../../../assets/styles/styles.scss';

.list-bg {
    background: $mai-kodama-white;
    padding: .63rem;
    @include border-radius();
}

.target-variable-wrapper {
    background: $mai-aliceblue;
    padding: .63rem;
    @include border-radius();
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .variables-inner {
        width: 26%;
        border-right: .06rem solid $mai-chinese-silver;
        padding-right: .63rem;
        margin-right: .63rem;
        min-height: 3.88rem;

        &:last-child {
            border-right: 0;
            margin-right: 0;
            padding-right: 0;
            width: 14%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
}

.variables-items-wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;

    .variables-items-wrap-inner {
        align-items: center;
        background: $mai-ice-castle;
        @include border-radius();
        display: flex;
        justify-content: space-between;
        padding: 0.313rem 0.625rem;
        width: 90%;

        .variables-items-bluedots {
            width: 1.25rem;
        }

        .variables-items-fields {
            padding: 0 0;
            text-align: left;
            width: calc(100% - 1.25rem);

            p {
                color: $black;
                font-size: .875rem;
                font-weight: 600;
                line-height: .938rem;
                margin: 0;
            }
        }
    }

    .variables-close {
        cursor: pointer;
        margin-left: 0.313rem;
        width: 10%;
    }
}

.channel-inner-wrap {
    flex-direction: column;
    align-items: flex-start;
    background: $mai-white-shade;
    border: .06rem dashed $mai-blueshade2;

    .heading-titles-wrap {
        width: 100%;
    }

    .var-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        .variables-inner {
            width: 24%;
            min-height: auto;
            border: none;
            margin-right: .32rem;
            padding-right: .32rem;
            margin-bottom: .32rem;
        }
    }

}

.createbutton {
    background: $mai-kodama-white;
    @include border-radius();
    color: $mai-blue-shade2;
    display: inline-block;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.313rem;
    padding: 0.438rem 0.375rem;
    text-decoration: none;

    &:hover {
        color: $mai-blue-shade2;
    }

    img {
        margin-right: .32rem;
    }
}