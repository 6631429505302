@import '../../../../../assets/styles/styles.scss';

.audiencedetails-table {

    tbody {
        td {

            &:first-child {
                color: $black;

                &:not(.p-highlight) {
                    &:hover {
                        color: $black !important;
                    }
                }

                &.p-highlight {
                    color: $black !important;
                }
            }

            &:not(.p-highlight) {
                &:hover {
                    background: transparent !important;
                    color: $black !important;
                }
            }

            &.p-highlight {
                outline: none !important;
                outline-offset: 0;
                background: transparent !important;
                color: $black !important;
            }

            &:focus {
                outline: none;
                outline-offset: 0;
                background: transparent;
                color: $black;
            }

            &:nth-child(3) {
                font-weight: 600 !important;
            }

            &:nth-child(4) {
                font-size: 0.875rem;
                font-weight: 600 !important;
                color: $black;

                &:after {
                    background: transparent;
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}

.raise {
    color: $mai-green-shade3;

    &:focus {
        color: $mai-green-shade3;
    }
}

.fall {
    color: red;

    &:focus {
        color: red;
    }
}

.product-badge {
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.625rem;
    letter-spacing: 0.019rem;
    margin: 0.1rem;

    @include border-radius(.125rem, .125rem, .125rem, .125rem);

    &.product-0 {
        background: $mai-granny-apple;
        color: $mai-greenshade4;
    }

    &.product-1 {
        background: $mai-shunglow;
        color: $mai-annatto;
    }

    &.product-2 {
        background: $mai-lightcyan;
        color: $mai-annatto;
    }
}

.top20customerwrap {

    .p-datatable-wrapper {

        .p-datatable-table {

            thead {

                th {
                    max-width: 13% !important;
                    width: 13%;
                    white-space: pre-wrap;

                    .p-column-header-content {

                        .p-column-title {
                            text-align: left;
                        }
                    }

                    &:first-child {
                        max-width: 19% !important;
                        width: 19%;
                    }

                    &:last-child {
                        max-width: 29% !important;
                        width: 29%;
                    }

                    .p-column-header-content {

                        .p-sortable-column-icon {
                            margin-left: 0.313rem;
                        }
                    }
                }

            }

            tbody {

                td {
                    max-width: 13% !important;
                    width: 13%;

                    &:first-child {
                        max-width: 19% !important;
                        width: 19%;
                    }

                    &:last-child {
                        max-width: 29% !important;
                        width: 29%;
                    }
                }

            }
        }
    }
}