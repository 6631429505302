@media only screen and (min-width: 1440px) {

    .insights-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.25rem 0 0;
        padding: 0;

        li {
            width: 33%;
            border-right: 0.063rem solid #C1C7D0;
            padding: 1.25rem 0.313rem 0.75rem;

            &:last-child {
                border-right: none;
            }

            h2 {
                color: #212121;
                font-size: 1rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                sub {
                    font-size: 0.813rem;
                    margin-left: 0.313rem;
                    bottom: -2px;
                }
            }

            p {
                line-height: 1.125rem;
                color: #8993A3;
                font-size: 0.688rem;
            }
        }
    }

}

// @media screen and (max-width: 480px) {
//     body, html {
//         font-size: 70%;
//     }
// }