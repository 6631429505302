$mai-green : Green;
$mai-blue-primary-1 : #002D9C;
$mai-dark-gray : #1A1C21;
$mai-blue-primary-2 : #4F75A5;
$mai-gray-primary-1 : #999999;
$mai-gray-primary-2 : #B8B8B8;
$mai-gray-primary-3 : #FBFCFD;
$mai-primary-red-1 : #CE2D51;
$mai-primary-red-2 : #D4374D;
$mai-dark-purple-shade : #2C1336;
$mai-gray-primary-4 : #E3E7EC;
$mai-gray-primary-5 : #F8F9FF;
$mai-light-silver : #d7d8dd;
$mai-gray-primary-7: #7F7F7F;
$mai-cool-gray : #8993A3;
$mai-blue-primary-4 : #022F9E;
$mai-gray-primary-8: #8993A3;
$mai-light-orange: #FFF1C5;
$mai-gray-shade : #F3F4F6;
$mai-light-purple-shade : #3e244b;
$mai-rosy-brown : #CB95A6;
$mai-dark-purple: #15011E;
$mai-vivid-blue :#3B82F6;
$mai-red-shade : #F43F5E;
$mai-green-shade : #35B47E;
$mai-yellow-shade : #FBBF24;
$mai-eerie-black : #212121;
$mai-stone-color : #C4C4C4;
$mai-white-smoke : #F4F4F4;
$mai-cyan-blue : #0d6efd;
$mai-dim-gray : #69707D;
$mai-link-water : #cfd4d7;
$mai-light-gray : #FAFAFA;
$mai-alice-blue : #eff5f9;
$mai-white-shade : #FBFBFB;
$mai-green-shade1 : #87D6B4;
$mai-blue-shade : #8592DE;
$mai-macroni-cheese : #F3B592;
$mai-red-shade1: #EC4A44;
$mai-blue-shade1: #6EC6D6;
$mai-orange-shade: #F5E5BC;
$mai-green-shade2: #C8F1D7;
$mai-green-shade3: #1C9A4E;
$mai-cadet-blue: #A4BCCC;
$mai-kodama-white: #ecf4fd;
$mai-blue-shde2: #0132aa;
$mai-lavender-mist: #d0d5ee;
$mai-lightblue-shade: #bdcff3;
$mai-ice-castle: #cce2fa;
$mai-aliceblue: #f8faff;
$mai-blueshade2: #d6ddff;
$mai-grayshade: #e5e5e5;
$mai-grayshade1: #333333;
$mai-grayshade2: #c5c3e1;
$mai-suvagray: #8A8A8A;
$mai-blizzard-blue: #B4DCF2;
$mai-gainsboro: #dfdfdf;
$mai-aliceblue-shade: #F8FBFF;
$mai-granny-apple: #c8e6c9;
$mai-greenshade4: #256029;
$mai-shunglow: #feedaf;
$mai-annatto: #8a5340;
$mai-lightcyan: #affefa;
$mai-whitesmoke-shade: #f1f1f1;
$mai-nero: #1A1A1A;
$mai-orangeshade: #E69864;
$mai-greenshade5: #77B896;
$mai-redshade2: #f51515;
$mai-grayshade3: #D1D1D1;
$mai-grayshade4: #bbbbbb;
$mai-aliceblue-shade: #F2F8FF;
$mai-blueshade: #D2D9FF;
$mai-darkgray: #495057;
$mai-blueshade3: #67799B;
$mai-blueshade4: #4777DA;
$mai-navy-blue: #005EC4;
$mai-white-shade1: #EFEFEF;
$mai-dark-shade: #444444;
$mai-blueshade5: #ADA9F0;
$mai-yellowshade1: #EDCD79;
$mai-greenshade6: #75AA75;
$mai-blueshade6: #C1C7D0;
$mai-greenshade7: #e9f5ee;
$mai-orangeshade2: #eaaf88;
$mai-orange-shade2: #f4e6de;
$mai-blue-shade2: #002d9c;
$mai-blueshade5: #e0e6f6;
$mai-cyan-blue-light: #ced4da;
$mai-gray-shade1: #eeeeee;
$mai-blue-shade3 : #e3e9f5;
$mai-blue-shade4: #2851af;
$mai-gray-shade2: #bcbcbc;
$mai-chinese-silver: #cccccc;
$mai-dark-gray-shade: #666666;
$mai-orange-shade3: #F9791C;
$mai-dark-blue: #1A2643;
$mai-blue-shade5: #5048E5;
$mai-light-grayshade: #E6E7EA;
$mai-gray-shade3: #f8f8f8;
$mai-lightblue-shade1: #B4E9F2;
$mai-red-shade2: #F1807B;
$mai-gray-shade6: #d9d9d9;
$mai-gray-shade4: #EAEAEA;
$mai-light-gray-shade: #c9c9c9;
$mai-alice-blue-shade: #f0f6fa;
$mai-redshade3: #BF3145;
$mai-blue-shade6: #ecf5fd;
$mai-pink-shade: #e57672;
$mai-darkgray-shade1: #212529;
$mai-davys-grey: #555555;
$mai-silver-grey: #c1c1c1;
$mai-light-red: #e3e3e3;
$mai-sea-green: #258049;
$mai-darklime-green: #69AA69;