@import '../../../../../assets/styles/styles.scss';

%box-shadow {
    background-color: $white;
    box-shadow: 0 0.25rem 0.5rem $mai-light-silver;
    padding: 1rem;

    @include border-radius(.5rem, .5rem, .5rem, .5rem, );
}

.datatable {
    thead {
        th {

            &:last-child {
                opacity: 1;

                .p-column-title {
                    background: $mai-ice-castle;
                    font-weight: 500;
                    font-size: .875rem;
                    line-height: 1.313rem;
                    text-align: center;
                    color: $mai-blue-primary-1;
                    display: inline-block;
                    padding: .438rem 1.563rem;
                    cursor: pointer;

                    @include border-radius();
                }
            }
        }

        td {

            &:focus {
                outline: none;
            }
        }
    }
}

.persona-comparison {
    @extend %box-shadow;

    .heading {
        margin: 0 0 1rem;
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        color: $black;
    }

    .persona-innerwrap {
        background: $mai-light-gray;
        border: .063rem solid $mai-blue-primary-4;
        padding: .625rem;
        @include border-radius();

        .form-check {

            input {
                border: .063rem solid $mai-blueshade;

                @include border-radius();

                &:checked {
                    background-color: $white;
                    background-image: url("../../../../../assets/images/tick-blue.svg");
                    background-repeat: no-repeat;
                    background-size: .5rem;
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .form-check-label {
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.5rem;
                text-align: right;
                color: $black;
            }
        }

        i {
            &.user {
                float: right;
                // color: $mai-green-shade1;
                font-size: 1.313rem;
                line-height: 1.313rem;
                font-size: 25px;
            }
        }

        .customer-revenue {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 1.438rem;
            margin-top: .625rem;

            .customer-revenue-inner {
                margin-right: 1rem;
                line-height: .875rem;
                width: 50%;
                border-right: 1px solid #c1c7d0;

                &:last-child {
                    border-right: none;
                    margin-right: 0;

                    .customer-revenue-inner__inner {
                        float: right;
                    }
                }

                p {
                    font-weight: 700;
                    font-size: .75rem;
                    line-height: .625rem;
                    color: $black;
                    margin: 0 0 5px;
                }

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: .75rem;
                    line-height: .625rem;
                    color: $mai-gray-primary-7;
                }
            }
        }
    }

}

.customer-segment-box {
    padding: 0;

    .insights__innerwrapper {
        box-shadow: none;
    }

    .segment-personas-tabs {

        .p-tabview-nav-container {
            float: left !important;
        }

        .Segments-datatable {

            th {
                background: $white !important;
                font-style: normal;
                font-weight: 600 !important;
                font-size: .813rem;
                line-height: .875rem;
                color: $mai-cool-gray !important;
                border: none !important;
            }

            td {
                padding: 1rem;
                font-weight: 700;
                font-size: .813rem;
                line-height: 1.313rem;
                color: $black;
                border-color: $mai-gray-shade;

                &:first-child {
                    font-style: normal;
                    font-weight: 500;
                    font-size: .813rem;
                    line-height: 1.188rem;
                    color: $mai-cool-gray;
                }

                &:hover {
                    background: transparent !important;
                    color: $black;
                }

                &:focus {
                    outline: none;
                }
            }
        }

        .customer__segment-chart {
            // display: flex;
            // align-items: center;
            // justify-content: space-evenly;
            background: $mai-white-shade;
            width: 100%;
            height: 100%;
            margin: 0;

            @include border-radius(.5rem, .5rem, .5rem, .5rem);

            .piechartpointers {
                width: 100%;
                min-height: 15.75rem;
                max-height: 15.75rem;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                overflow: scroll;
            }

            .customer_chart {
                text-align: center;
                // width: 100%;

                img {
                    width: 12.5rem;
                }

                h2 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 1.188rem;
                    text-align: center;
                    color: $black;
                    margin: 0 0 .938rem;
                }

            }

            .customer-segments-list {
                margin: 0rem 0 0;
                padding: 0 .938rem 0 0;

                li {

                    font-style: normal;
                    font-weight: 500;
                    font-size: .875rem;
                    line-height: 1.063rem;
                    color: $black;
                    margin-bottom: .938rem;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: 100%;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    i {
                        font-size: .438rem;
                        margin-right: .313rem;

                        &.color1 {
                            color: $mai-green-shade1;
                        }

                        &.color2 {
                            color: $mai-blue-shade;
                        }

                        &.color3 {
                            color: $mai-macroni-cheese;
                        }

                        &.color4 {
                            color: $mai-blue-shade1;
                        }

                        &.color5 {
                            color: $mai-orange-shade;
                        }
                    }

                    p {
                        margin: 0;

                        strong {}

                        span {
                            color: $mai-cool-gray;
                            font-size: .75rem;
                        }
                    }

                    .light-text {
                        color: $mai-cool-gray;
                        font-size: .75rem;
                    }
                }
            }
        }
    }
}

.geography-countriesbox {

    .p-tabview-nav-content {
        margin: -.44rem 0 0 0;
    }

    .filter {
        margin: 0 .313rem 0 0;
        cursor: pointer;
        position: relative;
        display: inline-block;
        z-index: 99;
    }

    .countriestable {
        th {
            display: none;
        }

        td {
            padding: 1rem;
            font-weight: 700;
            font-size: .813rem;
            line-height: 1.313rem;
            color: $black;
            border-color: $mai-gray-shade !important;

            &:hover {
                background: transparent !important;
                color: $mai-darkgray;
            }

            &:focus {
                outline: none !important;
            }
        }
    }
}

.filter-popup {

    .modal-dialog {
        max-width: 25rem;

        .modal-content {
            padding: 0 .625rem;

            @include border-radius();
        }

        .modal-header {
            padding-bottom: 0;

            @include modalHeader;

            h1 {
                @include modalTitle;
            }
        }

        .modal-body {

            label {
                font-weight: 700;
                font-size: .875rem;
                line-height: 1.313rem;
                color: $black;
                margin: 0 0 .188rem;
                opacity: 1;
            }

            select {
                background-color: $mai-gray-primary-3;
                border: .063rem solid rgba(19, 34, 149, 0.1);
                height: 2.5rem;
                font-weight: 400;
                font-size: .875rem;
                line-height: 1.313rem;
                color: $black;

                @include border-radius();

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .modal-footer {
            @include modalFooter;
        }

    }
}

.segmentmigration {
    .defineCalendar {
        margin-left: 0;

        .p-inputwrapper {

            input {
                height: 2.5rem;
            }
        }
    }

    .segmentmigration__migrationtitle {
        margin: 0 0 .625rem;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.125rem;
        color: $mai-dark-gray;
    }

    label {
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.125rem;
        color: $mai-dark-gray;
        margin-bottom: .188rem;

        span {
            font-weight: 400;
            font-size: .875rem;
            line-height: .9rem;
            color: $mai-cool-gray;
            margin-left: .625rem;
            display: inline-block;
        }
    }

    .p-inputwrapper {
        width: 100%;
        position: relative;

        ::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: '';
            background-image: url("../../../../../assets/images/calendar-icon.svg");
            background-repeat: no-repeat;
            background-size: cover;
            width: .813rem;
            height: .813rem;
        }

        input {
            background: $mai-gray-primary-3;
            border: .063rem solid rgba(19, 34, 149, 0.1);
            height: 2.5rem;
            @include border-radius();

            &:hover {
                border-color: rgba(19, 34, 149, 0.1);
            }

            &::placeholder {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.125rem;
                color: $black;
            }

            &:focus {
                box-shadow: none;
                border: .063rem solid rgba(19, 34, 149, 0.1);
            }

        }
    }

}

.inner__heading {
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    color: $black;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
        margin: 0 0.5rem;

        &:first-child {
            margin-left: 0;

        }
    }
}

.tabs-wrapper {
    .map-height {
        height: 15.125rem;
    }
}

.bordertop-header {
    border-top: 0.063rem solid $mai-white-smoke;
}

.industry-product-tabs {
    .segment-personas-tabs {
        .p-datatable-wrapper {
            .p-datatable-table {
                tbody {
                    tr {
                        &.p-selectable-row {
                            box-shadow: none;
                            outline: none;

                            &.p-highlight {
                                &:focus {
                                    box-shadow: none;
                                    outline: none;
                                }

                                td {
                                    background: rgb(196 196 196 / 10%);

                                    &:first-child {
                                        position: relative;

                                        &::before {
                                            width: .313rem;
                                            height: 66%;
                                            background: $mai-red-shade;
                                            position: absolute;
                                            left: 0;
                                            top: 50%;
                                            transform: translateY(-50%);
                                            content: '';

                                            @include border-radius(1.063rem, 1.063rem, 1.063rem, 1.063rem);
                                        }
                                    }

                                    &:hover {
                                        color: $black;
                                        background: rgb(196 196 196 / 10%) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .p-tabview {
            .p-tabview-nav-container {
                margin: -.5rem 0 0;
            }

            .p-tabview-panels {
                padding: 1rem 0 0;
            }
        }
    }
}

.industry-prod-datatable {

    .p-datatable {

        .p-datatable-wrapper {

            .p-datatable-table {

                thead {
                    th {
                        font-weight: 500;
                        font-size: .875rem;
                        line-height: 1.125rem;
                        color: $mai-cool-gray;
                        justify-content: flex-start;
                        padding: .625rem 0 .313rem 0;
                        border: none;
                        background: $white;
                        width: 33%;
                        overflow: visible;
                        white-space: pre-wrap;

                        &:first-child {
                            padding-left: .625rem;
                            padding-right: .625rem;
                        }

                        &:hover {
                            background: $white;
                            color: $mai-cool-gray;
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        .p-column-header-content {
                            justify-content: flex-start;

                            .p-column-title {
                                text-align: left;
                                width: 80%;
                            }

                            .p-sortable-column-icon {
                                color: $mai-cool-gray;
                                font-size: .75rem;
                                // margin: 0 0 0 .125rem;
                                // width: 20%;
                                text-align: left;
                            }
                        }
                    }
                }

                tbody {
                    .p-datatable-emptymessage {
                        td {
                            max-width: 100% !important;
                            height: 18rem;
                            text-align: center !important;
                        }
                    }

                    td {
                        font-weight: 500;
                        font-size: .875rem;
                        line-height: 1.125rem;
                        color: $black;
                        justify-content: flex-start;
                        border: none;
                        background: $white;
                        text-align: left;
                        padding: .625rem 0;
                        overflow: visible;
                        white-space: pre-wrap;
                        align-items: flex-start;

                        h3 {
                            &.data-per {

                                span {
                                    font-weight: 500;
                                    font-size: .875rem;
                                    line-height: 1.125rem;
                                    color: $black;
                                    opacity: 1;
                                }
                            }
                        }

                        &:hover {
                            background: $white !important;
                            color: $black;
                        }

                        &:focus {
                            outline: none !important;
                            box-shadow: none;
                        }

                        &:first-child {
                            font-size: .875rem;
                            color: $black;
                            font-weight: 500;
                            line-height: 1.125rem;
                            padding-left: .63rem !important;
                            padding-right: .63rem;

                            &:hover {
                                color: $black !important;
                            }

                            h3 {
                                span {
                                    color: #8993a3;
                                    line-height: 1rem;
                                }
                            }
                        }

                        &:nth-child(2) {
                            &:hover {
                                color: $black !important;
                            }
                        }

                    }
                }
            }
        }
    }
}

.audiencedetails__tabs {

    .p-tabview {

        .p-tabview-panels {
            background: transparent;
            padding: 1rem;
        }

        .p-tabview-nav-container {
            padding: 0 .938rem;
            background: $white;

            .p-tabview-nav-content {

                .p-tabview-nav {
                    border: none;

                    li {

                        &.p-unselectable-text {
                            border-right: 0.063rem solid $mai-white-smoke;
                            padding: 0 .63rem;
                            margin-right: 0;

                            &:nth-last-of-type(2) {
                                border-right: none;
                            }

                            &.p-tabview-selected {

                                a {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 1rem;
                                    line-height: 1.188rem;
                                    color: $mai-blue-primary-1;
                                    opacity: 1;
                                    position: relative;

                                    &:hover {
                                        opacity: 1;
                                        color: $mai-blue-primary-1;
                                    }
                                }
                            }

                            a {
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1.188rem;
                                text-align: center;
                                color: $black;
                                opacity: 0.5;
                                position: relative;
                                padding: .813rem 0;
                                margin: 0 0.5rem;
                                border: none;

                                &:hover {
                                    color: $black;
                                    opacity: 0.5;
                                }

                                &:focus {
                                    outline: none;
                                    box-shadow: none;
                                }
                            }

                        }

                        &.p-tabview-ink-bar {
                            height: .25rem;
                            background-color: $mai-blue-primary-1;
                            border-right: none;
                            @include border-radius();
                            margin: 0 .63rem;
                        }
                    }


                }
            }
        }

    }
}

.personatabsbgwhite {
    .p-tabview {
        .p-tabview-nav-container {
            .p-tabview-nav-content {
                .p-tabview-nav {
                    background: $white;
                }
            }
        }
    }
}

.top5countries {
    .p-datatable {

        .p-datatable-emptymessage {
            td {
                max-width: 100% !important;
                height: 19.94rem;
                text-align: center !important;
            }
        }

        .p-datatable-wrapper {
            max-height: 19.94rem !important;
            min-height: 19.94rem !important;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 .063rem $white;
                @include border-radius(0, 0, 0, 0);
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: .35rem;
                background-color: transparent;
                height: .32rem;
            }

            &::-webkit-scrollbar-thumb {
                @include border-radius();
                background: $mai-gray-shade6;
                min-height: 6.25rem;
            }

            .p-datatable-table {

                thead {
                    display: none;

                    th {
                        padding: .32rem;
                        border: none;
                        font-size: .88rem;
                        font-weight: 700;
                        text-align: left;
                        max-width: 15%;

                        &:first-child {
                            max-width: 25%;
                        }

                        &.p-sortable-column {
                            &:not(.p-highlight) {
                                &:not(.p-sortable-disabled) {
                                    &:hover {
                                        background: transparent !important;
                                        color: $black !important;
                                    }
                                }
                            }
                        }

                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }

                        &.p-highlight {
                            background: transparent;
                            box-shadow: none;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: .75rem;
                            font-weight: 700;
                            color: $black;
                            padding: .32rem;
                            text-align: left;
                            justify-content: flex-start;
                            flex-wrap: wrap;
                            align-items: flex-start;
                            max-width: 15%;
                            width: 15%;
                            flex-direction: column;

                            &.p-selectable-cell {
                                &:not(.p-highlight) {
                                    &:hover {
                                        background: transparent !important;
                                        color: $black !important;
                                    }
                                }
                            }

                            &:focus {
                                outline: none;
                                box-shadow: none;
                            }

                            &.p-highlight {
                                background: transparent;
                                box-shadow: none;
                            }

                            span {
                                color: $mai-cool-gray;
                                font-weight: 400;
                                font-size: .75rem;
                                line-height: 1rem;
                                display: block;
                            }

                            &:first-child {
                                max-width: 25%;
                                width: 25%;
                            }

                            &.p-selectable-cell {
                                &:focus {
                                    outline: none !important;
                                }
                            }

                        }

                        &:last-child {
                            td {
                                border-bottom: none;
                            }
                        }
                    }

                }
            }
        }

    }
}

.chartsminheight {
    min-height: 23.75rem;
}

.segmenteditfield {
    background-color: $white !important;
    color: $black !important;
}

.persona-geography-wrap {

    .p-tabview-nav-content {
        margin-top: 0;
    }

    .p-tabview-panels {
        padding: 0 !important;
    }
}

.segments-table {
    .p-datatable-wrapper {
        max-height: 31.25rem !important;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 .063rem $white;
            @include border-radius(0, 0, 0, 0);
            background-color: transparent;
        }

        &::-webkit-scrollbar {
            width: .35rem;
            background-color: transparent;
            height: .32rem;
        }

        &::-webkit-scrollbar-thumb {
            @include border-radius();
            background: $mai-gray-shade6;
            min-height: 6.25rem;
        }

        .p-datatable-table {

            thead {

                th {
                    white-space: pre-wrap;
                    justify-content: flex-start;
                    // max-width: 13.34% !important;

                    &:first-child {
                        min-width: 11.57rem !important;
                    }

                    .p-column-header-content {
                        text-align: left;
                        justify-content: flex-start;
                    }
                }

            }

            tbody {
                td {
                    &.p-align-center {
                        &:first-child {
                            white-space: nowrap !important;
                            min-width: 11.57rem !important;
                            color: $black;

                            &:hover {
                                color: $black !important;
                            }

                            &:focus {
                                color: $black;
                            }

                            span {
                                max-width: 7.82rem;
                                width: 7.82rem;
                                display: inline-block;
                            }

                            span[role="button"] {
                                width: 1.07rem;
                                height: 1rem;
                                background: url("../../../../../assets/images/edit-underline-blue.png") no-repeat scroll 0 .13rem;
                                background-size: 100%;
                                display: inline-block;

                                i {
                                    display: none;
                                }
                            }
                        }

                        &:last-child {
                            justify-content: flex-start !important;
                        }
                    }
                }
            }
        }
    }
}

.countriesNameIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    background-color: rgb(0 0 0 / 3%);
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        font-size: 22px;
        line-height: 22px;
        opacity: 1;
    }
}