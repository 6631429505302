@import "../../../../../../assets/styles/styles.scss";

.definewrapper {

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.125rem;
        color: $mai-dark-gray;
        margin: 0;
    }

    h3 {
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 160%;
        color: $mai-gray-primary-7;
        margin: .313rem 0 0;
    }

    .formcheck-segment {
        background: $white;
        border: .063rem solid $mai-grayshade3;
        padding: 1rem 2rem;
        min-height: 7.5rem;

        @include border-radius();

        label {
            font-style: normal;
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.188rem;

            color: $black;

            span {
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: .875rem;
                line-height: 160%;
                color: $mai-gray-primary-7;
            }
        }
    }

    .defineCalendar {
        display: inline-block;
        margin-left: .313rem;

        .p-inputwrapper {
            @include border-radius(.25rem, .25rem, .25rem, .25rem);

            input {
                background-color: $mai-gray-primary-3;
                border: 0.063rem solid rgba(19, 34, 149, 0.1);
                color: $black;
                font-size: .875rem;
                font-weight: 500;
                height: 2.188rem;

                @include border-radius(.25rem, .25rem, .25rem, .25rem);
            }
        }


    }
}

.invaliddate {
    border: .063rem solid $mai-redshade2;

    @include border-radius(.438rem, .438rem, .438rem, .438rem);
}

.p-datepicker:not(.p-datepicker-inline) {

    .p-datepicker-header {
        padding: 0 .5rem .5rem;

        .p-datepicker-title {
            button {
                padding-top: 0;
                padding-bottom: 0;

                &:enabled{
                    &:hover{
                        color: $black;
                    }
                }

                &:focus{
                    box-shadow: none;
                }
            }
        }
    }

    .p-datepicker-calendar {
        margin-bottom: 0;

        thead {

            th {
                padding: 0;
                text-align: center;
            }
        }

        tbody {

            td {
                padding: 0;

                span {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
}