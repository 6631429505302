@import "../../../../../../assets/styles/styles.scss";

.chooseattribute {
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.125rem;
    color: $mai-dark-gray;
}

.drop_area {
    min-height: 6.25rem;
}

.customer-attribute-wrap {
    .rules__includeexclude-body {
        padding-bottom: 0;
        padding-top: 0.63rem;

        .rules_group_wrapper {
            justify-content: flex-start;

            .rules__includes-data {
                margin-left: 0;
            }
        }
    }
}