@import '../../../../../assets/styles/styles.scss';

.audiencedetails__title {
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.813rem;
    color: $black;
    padding: 0 1rem;

    span {
        display: inline-block;
        margin: 0 0.625rem;
    }

    a {
        display: inline-block;
    }
}

.audiencedetails__tabs {

    ul.nav {
        background-color: $white;
        padding: 0 1rem;

        li.nav-item {
            border-right: 0.063rem solid $mai-white-smoke;
            padding: 0 0.313rem;

            &:last-child {
                border-right: none;
            }

            a.nav-link {
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.188rem;
                text-align: center;
                color: $black;
                opacity: 0.5;
                position: relative;
                padding: 0.688rem 0;
                margin: 0 0.5rem;

                &.active {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 1.188rem;
                    color: $mai-blue-primary-1;
                    opacity: 1;
                    position: relative;

                    &::after {
                        width: 100%;
                        height: 0.25rem;
                        background-color: $mai-blue-primary-1;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        content: '';

                        @include border-radius();
                    }
                }
            }
        }
    }

    .tab-content {
        padding: 1rem 1rem;

        .audiencedetails_calendar {
            position: relative;
            display: inline-block;
            padding: 0.375rem 0;

            label {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1.188rem;
                color: $mai-gray-primary-7;
                margin-right: 0.438rem;
            }

            input {
                padding: 0;
                background: transparent;
                border: none;
                width: 10.625rem;
                color: $mai-blue-primary-1;
                font-weight: 600;
                font-size: 1rem;

                &::placeholder {
                    font-weight: 600;
                    font-size: 1rem;
                    line-height: 1.313rem;
                    color: $mai-blue-primary-1;
                }

                &:focus-visible {
                    outline: none;
                }
            }

            img {
                position: absolute;
                right: 0.438rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .audiencedetails__insight-topheading {
            margin-bottom: 0.938rem;


            .audiencedetails_region {
                display: inline-block;

                .audiencelist__select {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: relative;

                    label {
                        width: 5.813rem;
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 1.188rem;
                        color: $mai-gray-primary-7;
                        margin-right: 0.438rem;
                        text-align: right;
                    }

                    select {
                        width: 3.125rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1.313rem;
                        color: $mai-blue-primary-1;
                        border: none;
                        padding-left: 0.625rem;
                        padding-right: 0.938rem;
                        background-position: right;
                        background: transparent;
                        position: relative;

                        &:focus {
                            box-shadow: none;
                        }

                    }

                    img {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }


        }
    }
}

.heading-button {

    a {
        background: $white;
        border: 0.063rem solid $mai-ice-castle;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.313rem;
        text-align: center;
        color: $mai-blue-primary-1;
        padding: 0.438rem 0.938rem;
        text-decoration: none;
        margin-right: 0.438rem;
        display: inline-block;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);

        &::after {
            border: none;
        }

        &:last-child {
            margin-right: 0;
        }

        img {
            margin-right: 0.313rem;
        }
    }

    ul {
        &.dropdown-menu {
            box-shadow: rgb(195 195 195) 0 0.3rem 0.3rem;
            border: none;
            padding: 0.438rem 0;
            width: 12.5rem;

            @include border-radius(.25rem, .25rem, .25rem, .25rem);

            li {

                a {
                    border: none;
                    text-align: left;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5rem;
                    color: $black;
                    padding: 0.313rem 0.938rem;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.customer-button {

    a {
        background: $mai-kodama-white;
    }

    ul {

        li {

            a {
                background: transparent;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }
}

.audiencelist__select {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
        width: 5.813rem;
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $mai-gray-primary-7;
        text-align: right;
    }

    select {
        width: auto;
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.313rem;
        color: $mai-blue-primary-1;
        border: none;
        padding-left: 0.625rem;
        padding-right: 0.938rem;
        background-position: right;

        &:focus {
            box-shadow: none;
        }

    }
}

.insights-addkpi {
    background: $mai-alice-blue;
    border: 0.125rem dashed $mai-gainsboro;
    box-shadow: none;

    .addkpi-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: $mai-cadet-blue;
        text-decoration: none;
        padding: 1.813rem 0;

        img {
            margin-bottom: 0.438rem;
        }
    }

}

.map-wrapper {
    padding: 1.25rem 0 0;
    width: 100%;
}

.map-height {
    width: 100%;
    height: 15.625rem;
}



.audiencedetails__addkpimodal {

    .modal-dialog {
        max-width: 51.25rem;

        .modal-header {
            border: none;
            padding-bottom: 0.313rem;

            h1 {
                font-weight: 700;
                font-size: 1.25rem;
                line-height: 1.75rem;
                color: $black;
            }
        }

        .modal-body {

            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.313rem;
                color: $black;
            }

            .kpi-positioning {
                background: $mai-alice-blue;
                border: 0.063rem solid $mai-blizzard-blue;
                box-shadow: 0 0.125rem 0.375rem rgb(43 43 43 / 7%);
                padding: 0.625rem 0.938rem;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.188rem;
                color: $black;
                height: 2.5rem;

                @include border-radius(.25rem, .25rem, .25rem, .25rem);

                img {
                    margin-right: 0.625rem;
                }
            }

            .listof-kpi {

                .form-check {

                    input {
                        background-color: $mai-stone-color;
                        border: none;
                        width: 0.75rem;
                        height: 0.75rem;

                        @include border-radius(.125rem, .125rem, .125rem, .125rem);

                        &:checked {
                            background-color: $mai-blue-primary-1;
                            border-color: $mai-blue-primary-1;
                            ;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                    }

                    label {
                        font-weight: 700;
                        font-size: 0.875rem;
                        line-height: 1.125rem;
                        color: $black;

                        span {
                            display: block;
                            font-weight: 400;
                            font-size: 0.75rem;
                            line-height: 1.25rem;
                            color: $mai-cool-gray;
                        }
                    }
                }

            }
        }

        .modal-footer {
            border: none;

            button {
                background-color: $mai-blue-primary-1;
                border-color: $mai-blue-primary-1;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.313rem;
                color: $white;
                padding: 0.5rem 2.563rem;

                @include border-radius();
            }
        }
    }
}

.dataTable {

    tr {

        &:nth-child(2n+2) {
            background: $mai-aliceblue-shade;
        }
    }

    th {
        background: $mai-white-shade !important;
        font-weight: 500 !important;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: $black !important;
        opacity: 0.5;
        padding: 0.5rem 1rem !important;
        border: none !important;
    }

    td {
        font-style: normal;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.063rem;
        color: $black;
        border: none !important;

        &:hover {
            background-color: transparent !important;
        }

        &:first-child {
            font-style: normal;
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.063rem;
            color: $black;

            &:not(.p-highlight) {
                &:hover {
                    color: $black;
                }
            }
        }
    }
}

.calendar-popup {

    .modal-dialog {
        max-width: 40.625rem;
    }

    .modal-body {

        input {
            width: 6.25rem;
            height: 2.5rem;
            background: $mai-gray-primary-3;
            border: 0.063rem solid rgba(19, 34, 149, 0.1);
            font-weight: 500;
            font-size: 1rem;
            color: $black;

            @include border-radius();

            &::placeholder {
                color: $black;
            }

            &:focus {
                box-shadow: none;
            }
        }

        label {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: $mai-suvagray;
        }

        .selected-period {

            p {
                margin: 0;
                font-weight: 700;
                font-size: 0.875rem;
                line-height: 1.125rem;
                color: $mai-suvagray;

                span {
                    display: block;
                    font-weight: 700;
                    font-size: 0.875rem;
                    line-height: 1.063rem;
                    color: $mai-blue-primary-1;
                }
            }
        }
    }
}

.audiencedetails-wrap {
    border-top: 0.063rem solid $mai-whitesmoke-shade;
}

.innertext__size {

    h2 {
        sub {
            bottom: 0;
            margin-left: 0.125rem;
        }
    }
}

.industries_datatable {

    .p-datatable-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 .063rem $white;
        @include border-radius(0, 0, 0, 0);
        background-color: transparent;
    }

    .p-datatable-wrapper::-webkit-scrollbar {
        width: .35rem;
        background-color: transparent;
        height: .32rem;
    }

    .p-datatable-wrapper::-webkit-scrollbar-thumb {
        @include border-radius();
        background: $mai-gray-shade6;
        min-height: 6.25rem;
    }

    .p-datatable-table {

        .p-datatable-emptymessage {
            td {
                max-width: 100% !important;
                height: 18.75rem;
                text-align: center !important;
            }
        }

        .p-sortable-column {
            &:not(.p-highlight) {
                &:not(.p-sortable-disabled) {
                    &:hover {
                        background: $white;
                        color: $black;
                    }
                }
            }
        }


        .p-sortable-column {
            &.p-highlight {
                &:not(.p-sortable-disabled) {
                    &:hover {
                        background: $white;
                        color: $black;
                    }
                }
            }
        }

        .p-sortable-column {
            &.p-highlight {
                background: $white;
                color: $black;
            }
        }

        thead {

            th {
                padding-top: 0;
                padding-bottom: 7px;
                background: $white;
                border: none;
                font-style: normal;
                font-weight: 700;
                font-size: 1rem;
                line-height: 1.3rem;
                color: $black;
                max-width: 50%;

                .p-column-header-content {

                    .p-sortable-column-icon {
                        font-size: 0.75rem;
                        color: $mai-silver-grey;
                    }
                }

                &:first-child {
                    max-width: 25%;
                }

                &:last-child {
                    max-width: 25%;
                }


                &:focus {
                    box-shadow: none;
                    background: transparent;
                }
            }
        }

        tbody {

            td {
                padding-top: 0.438rem;
                padding-bottom: 0.438rem;
                border: none;
                font-weight: 500;
                font-size: 0.875rem;
                line-height: 1.063rem;
                text-align: right !important;
                color: $mai-cool-gray;
                max-width: 50%;
                width: 50%;

                &:first-child {
                    width: 25%;
                    max-width: 25%;
                    font-weight: 400;
                }

                &:last-child {
                    max-width: 25%;
                    width: 25%;
                }

                &:hover {
                    background: $white !important;
                }

                &:focus {
                    box-shadow: none;
                    outline: none !important;
                }

                .progressbar {
                    width: 100%;
                    text-align: left;
                }

                &.p-highlight {
                    background: transparent;
                    color: $mai-cool-gray;
                }

                .data-per {
                    text-align: left;
                    margin: 0.625rem 0 -0.625rem;
                    width: 100%;
                }
            }
        }

        .p-datatable-emptymessage {
            td {
                justify-content: center !important;
                min-height: 21.563rem;
            }
        }
    }
}

.industries-customerRevenue {
    min-height: 25rem !important;

    .p-datatable-scrollable {

        .p-datatable-wrapper {
            max-height: 21.5rem !important;
        }
    }

    .p-datatable-table {

        tbody {

            td {

                &:first-child {
                    white-space: pre-line;
                    text-align: right;
                    justify-content: flex-end;
                }
            }
        }
    }
}

.top20customerwrap {

    thead {
        th {

            &:last-child {
                max-width: 40% !important;
            }
        }
    }

    tbody {
        td {

            &:last-child {
                max-width: 40% !important;

                span {

                    &.product-badge {
                        display: inline-block;
                        width: 5.5rem;
                        text-align: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        @include border-radius();
                    }
                }
            }
        }
    }
}

.customerRevenue {
    min-height: 25rem !important;
}

.productsChart {
    min-height: 25rem !important;
}

.customtabs-wrap {
    .customtabs {

        .p-tabview {

            .p-tabview-nav-container {

                .p-tabview-nav-content {

                    .p-tabview-nav {

                        li {

                            &.p-tabview-selected {
                                a {
                                    font-size: 0.875rem;

                                    &:hover {
                                        color: $mai-blue-primary-1;
                                    }
                                }
                            }

                            a {
                                padding: 0.5rem 0.5rem;
                                margin: 0.188rem 0.5rem;
                                font-size: 0.875rem;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.customers-datatable {

    .p-datatable.p-datatable-selectable-cell {
        .p-datatable-tbody {
            >tr.p-selectable-row {
                background: $white;

                &.p-row-odd {
                    background: $mai-aliceblue-shade;

                    >td.p-selectable-cell {
                        &.p-highlight {
                            background: $mai-aliceblue-shade;
                        }
                    }
                }

                >td.p-selectable-cell {
                    min-height: 1rem;
                    padding: .88rem .5rem;

                    &:first-child {
                        &:not(.p-highlight):hover {
                            color: $black;
                        }

                        &.p-highlight {
                            color: $mai-blue-primary-4;
                        }
                    }

                    &:focus {
                        outline: none;
                    }

                    &.p-highlight {
                        background: $white;
                        color: $black;
                    }

                    &:not(.p-highlight):hover {
                        color: $black;
                    }
                }
            }
        }
    }

    // .p-datatable-table {
    //     &.p-datatable-selectable-cell {
    //         .p-datatable-tbody {
    //             tr {
    //                 &.p-selectable-row {
    //                     background: $white;

    //                     &.p-row-odd {
    //                         background: $mai-aliceblue-shade;
    //                     }

    //                     td {
    //                         &.p-selectable-cell {
    //                             min-height: 1rem;
    //                             padding: .88rem .5rem;

    //                             &:focus {
    //                                 outline: none;
    //                             }
    //                         }

    //                     }
    //                 }


    //             }
    //         }


    //     }

    // }

    .p-datatable-scrollable {
        .p-datatable-wrapper {
            max-height: 25.44rem !important;
            min-height: 25.44rem;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 .063rem $white;
                @include border-radius(0, 0, 0, 0);
                background-color: transparent;
            }

            &::-webkit-scrollbar {
                width: .35rem;
                background-color: transparent;
                height: .32rem;
            }

            &::-webkit-scrollbar-thumb {
                @include border-radius();
                background: $mai-gray-shade6;
                min-height: 6.25rem;
            }
        }
    }
}

.audience-customer-whitebox-height {
    height: calc(100vh - 8.2rem);
}