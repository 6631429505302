@import '../../../../../../assets/styles/styles.scss';

.minheightWhiteBox {
    min-height: 24.1rem;
}

.kpitobetested {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .kpi {
        label {
            position: relative;

            @include border-radius(2.19rem, 2.19rem, 2.19rem, 2.19rem);
            font-weight: 400;
            font-size: .88rem;
            line-height: 1.06rem;
            margin-right: .5rem;
            padding: .5rem .63rem;

            span {
                display: inline-block;
                margin-left: .75rem;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: .63rem;
                left: .5rem;
                width: .38rem;
                height: .69rem;
                border: solid $mai-gray-shade2;
                border-width: 0 .06rem .06rem 0;
                transform: rotate(45deg);
                cursor: pointer;
            }
        }

        input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;

            + {
                label {
                    background: $mai-gray-primary-3;
                    border: .06rem solid rgba(19, 34, 149, 0.1);
                    color: $mai-gray-primary-2;
                    cursor: pointer;
                }
            }


            &:checked {
                + {
                    label {
                        background: $mai-ice-castle;
                        border: .06rem solid $mai-ice-castle;
                        color: $black;

                        &:after {
                            content: '';
                            display: block;
                            position: absolute;
                            top: .63rem;
                            left: .5rem;
                            width: .38rem;
                            height: .69rem;
                            border: solid $mai-blue-shade4;
                            border-width: 0 .06rem .06rem 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

        }

        &:last-child {
            margin-right: 0;
        }

    }

    a {
        text-decoration: none;
        background: $mai-ice-castle;
        border: .06rem solid $mai-ice-castle;
        @include border-radius(2.19rem, 2.19rem, 2.19rem, 2.19rem);
        font-weight: 400;
        font-size: .88rem;
        line-height: 1.06rem;
        color: $black;
        margin-right: 1rem;
        padding: .5rem .63rem;
        display: inline-block;

        &.grayBtn {
            background: $mai-gray-primary-3;
            border: .06rem solid rgba(19, 34, 149, 0.1);
            color: $mai-gray-primary-2;

            img {
                filter: grayscale(1);
                opacity: 0.3;
            }
        }

        img {
            margin-right: .13rem;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}