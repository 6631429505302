.cutomtabswrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .p-tabmenu {

        .p-tabmenu-nav {
            background: #f0f6fa;
            border: none;
            border-radius: 5px;
            padding: 3px;

            .p-tabmenuitem {
                .p-menuitem-link {
                    font-size: 0.875rem;
                    margin: 0;
                    padding: 0.45rem 0.7rem;
                    border: none;
                    background: transparent;
                    border-radius: 0.313rem 0.313rem 0.313rem 0.313rem;
                    box-shadow: none;
                    font-weight: 400;
                    opacity: 0.8;
                    height: auto;

                    &:hover {
                        background: transparent;
                    }

                    &:not(.p-disabled) {
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                &.p-highlight {
                    .p-menuitem-link {
                        font-size: 0.875rem;
                        color: #002d9c;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 1.188rem;
                        opacity: 1;
                        position: relative;
                        background: #fff;
                        margin: 0;
                        height: auto;

                        &:focus {
                            box-shadow: none;
                        }
                    }

                }

                &:not(.p-highlight) {
                    &:not(.p-disabled) {
                        &:hover {
                            .p-menuitem-link {
                                background: transparent;
                            }
                        }
                    }
                }
            }

            .p-tabmenu-ink-bar {
                display: none;
            }
        }
    }
}