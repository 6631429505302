@import '../../../../../../assets/styles/styles.scss';

.title16 {
    @include titleFonts16;
}

.testtargetwrapper {

    .testdescbox {
        background: $mai-gray-primary-3;
        border: .07rem solid rgba(19, 34, 149, 0.1);
        @include border-radius();
        text-align: center;
        min-height: 7.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        h2 {
            font-weight: 700;
            font-size: .88rem;
            line-height: 1.07rem;
            color: $mai-dark-blue;
            margin: .63rem 0 0;
        }
    }

    .subjectlinebox {
        background: rgba(80, 72, 229, 0.8);
        border: .07rem solid $mai-blue-shade5;
        box-shadow: 0 .25rem .25rem rgba(0, 0, 0, 0.25);
        @include border-radius();
        text-align: center;
        min-height: 7.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        h2 {
            font-weight: 700;
            font-size: .88rem;
            line-height: 1.07rem;
            color: $white;
            margin: 0 0 1rem;
        }
    }


    .plusminuswrap {

        .plusminuswrap__inner {

            &:first-child {
                @include border-radius(.32rem, 0, 0, .32rem);
                background: rgb(239 239 239 / 50%);
            }

            &:nth-child(2) {
                @include border-radius(0, 0, 0, 0);
            }

            &:nth-child(3) {
                @include border-radius(0, .32rem, .32rem, 0);
                background: rgb(239 239 239 / 50%);
            }

            &.plusminuswrap__inner-middle {
                background: $white;
            }
        }
    }

}

.subjectbox {
    background: $mai-gray-primary-3;
    border: .07rem solid rgba(19, 34, 149, 0.1);
    @include border-radius();
    padding: .63rem 1.25rem;

    .deleteiconbox {
        line-height: 1.25rem
    }
}

.font16 {
    @include titleFonts16;
}

.deleteimg {
    cursor: pointer;
    display: inline-block;
}