@import '../../../../../../assets/styles/styles.scss';

.summery-shadow {
    box-shadow: 0 .188rem .5rem $mai-link-water;
    padding: 1.25rem;

    @include border-radius(.25rem, .25rem, .25rem, .25rem);
}

.summery__waterfall {

    .summery__heading {
        margin: 0 0 1.25rem;
        font-style: normal;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.375rem;
        color: $black;

        img {
            margin-left: .313rem;
        }
    }

    .summery__waterfalllist {
        margin: 0;
        padding: 0;
        list-style: none;
        min-height: 3rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        li {
            list-style: none;
            margin: 0;
            font-weight: 600;
            font-size: .875rem;
            line-height: 1.063rem;
            color: $black;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;

            .customtooltip {
                position: relative;

                .customtooltiptext {
                    position: absolute;
                    left: 5% !important;
                    top: 2.13rem !important;
                    width: 100%;
                    background: $mai-dark-purple-shade;
                    font-size: .75rem;
                    font-weight: normal;

                    &::after {
                        border-color: transparent transparent $mai-dark-purple-shade transparent;
                    }
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            img {
                margin-right: .938rem;
            }
        }
    }
}

.summary__audience-revenue {
    background: $mai-light-gray;
    padding: .938rem;
    height: 100%;

    @include border-radius(.25rem, .25rem, .25rem, .25rem);

    .cohart_container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 12.5rem;
        height: 12.5rem;
        margin-top: 0.313rem;

    }

    .summary__size {
        padding-left: 1.125rem;
        margin: 0 0 0.625rem;
        position: relative;
        z-index: 999;

        .audience_chartdata {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1.7rem;

            sub {
                font-weight: 500;
                font-size: 1.063rem;
            }
        }

        h2 {
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.813rem;
            color: $mai-blue-primary-1;

            sub {
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.5rem;
                color: $mai-blue-primary-1;
            }
        }

        p {
            margin: 0;
            font-weight: 400;
            font-size: .875rem;
            line-height: 1.375rem;
            color: $mai-gray-primary-7;
        }
    }
}

.summery__waterfall-chart {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .bluecolorshade {
        background: $mai-blue-primary-1;
        margin-bottom: 0.625rem;
        color: $white;
        padding: 0.438rem;
        min-height: 2.375rem;
        width: 100%;
        box-shadow: 0 0.563rem 0.188rem $mai-grayshade2;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);
    }
}