.info {
    display: inline-block;
    padding: 0px 4px 15px 4px;
    color: #6d20b6;
    font-size: 12px;
    border-radius: 50%;
    border: solid 2px #6524a1;
    width: 20px;
    height: 21px;
    font-weight: bold;
    text-align: center;
}