@import '../../assets/styles/styles.scss';

%login-agreement-text {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.25rem;
}

%login-wrapper {
    width: 100%;
    padding: 2% 6% 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

%login-inner-width {
    width: 90%;
}

.mai-login {
    .loginBg {
        background-color: $mai-light-purple-shade;
    }

    .login__box {
        @extend %login-wrapper;
        position: relative;

        img {
            &.login__box-evsshort-logo {
                position: absolute;
                bottom: 40px;
                right: 30px;

            }
        }

        .login__inner {
            width: 90%;

            .login__logo {
                margin-bottom: 3.125rem;
            }

            .login__title {
                margin-bottom: 0.938rem;

                h1 {
                    margin: 0;
                    font-size: 2rem;
                    color: $black;
                    font-weight: 700;
                }
            }

            .login__form-group {

                .login__label {
                    font-weight: 700;
                    margin-bottom: 0.3rem;
                    color: $mai-dark-gray;
                    font-size: 1.0625rem;
                }

                .login__input {
                    border: 0.063rem solid rgba(19, 34, 149, 0.1);
                    background: $mai-gray-primary-3;

                    @include border-radius();

                    &::placeholder {
                        color: $mai-gray-primary-2;
                        font-size: 0.875rem;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                .login__forgotpass {
                    color: $mai-blue-primary-1;
                    font-size: 1rem;
                    font-weight: 400;
                    text-decoration: none;
                }
            }

            .login__button-group {
                margin: 1.875rem 0;

                button {
                    background-color: $mai-blue-primary-1;
                    padding: 0.59rem 3.25rem;
                    line-height: 1.25rem;
                    font-size: 0.875rem;

                    @include border-radius(.375rem, .375rem, .375rem, .375rem);
                }
            }

            .login__agreement {
                line-height: 1.125rem;

                .login__agreement-para {
                    margin: 0;
                    color: $mai-gray-primary-1;

                    @extend %login-agreement-text;
                }

                .login__agreement--terms {
                    color: $mai-blue-primary-2;
                    text-decoration: none;

                    @extend %login-agreement-text;
                }
            }

            .login__copyright {

                .login__copyright-text {
                    margin: 0.938rem 0 0;
                    color: $mai-gray-primary-1;
                    opacity: 0.7;

                    @extend %login-agreement-text;
                }
            }

            .login__invalid {
                color: #dc3545;
                display: block;
                margin: 0.25rem 0 0;
                width: 100%;
                font-size: 14px;
            }
        }
    }

    .login__leftsection {
        @extend %login-wrapper;


        .login__leftsection-inner {
            width: 100%;

            .login__leftsection-heading {

                .login__leftheading-title {
                    font-size: 1.6rem;
                    color: $white;
                    line-height: 2.375rem;
                    font-weight: 500;
                    margin: 0;
                }
            }

            .login__subheading {

                .login__subheading-title {
                    font-size: 1.5rem;
                    color: $white;
                    font-weight: 500;
                    line-height: 1.813rem;
                    margin: 4.625rem 0 2rem;
                    position: relative;

                    &::after {
                        position: absolute;
                        bottom: -0.438rem;
                        left: 0;
                        content: '';
                        width: 4.938rem;
                        height: 0.188rem;
                        background: $mai-primary-red-1;

                        @include border-radius(.5rem, .5rem, .5rem, .5rem);
                    }
                }
            }

            .login__whatsnew-box {

                .login__whatsnew-inner {

                    figure {
                        position: relative;
                        margin: 0;
                        width: 100%;

                        &::after {
                            background: linear-gradient(360deg, $mai-dark-purple -8.29%, rgba(63, 37, 75, 0) 100%);
                            filter: drop-shadow(0 0.375rem 1rem rgba(0, 0, 0, 0.05));
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;

                            @include border-radius();
                        }

                        .login__whatsnew-img {
                            width: 100%;
                        }

                        .login__whatsnew-desc {
                            position: absolute;
                            bottom: 1.5rem;
                            margin: 0 0.938rem;
                            z-index: 9;

                            .login__img-desc {
                                font-size: 1.3rem;
                                color: $white;
                                line-height: 1.613rem;
                                font-weight: 500;
                                margin-bottom: 0.938rem;
                            }

                            .login__knowmore {
                                font-weight: 500;
                                font-size: 0.875rem;
                                line-height: 1.25rem;
                                color: $mai-primary-red-2;
                                text-decoration: none;

                                .login__right-arrow {
                                    border: solid $mai-primary-red-2;
                                    border-width: 0 0.125rem 0.125rem 0;
                                    display: inline-block;
                                    padding: 0.125rem;
                                    transform: rotate(-45deg);
                                    -webkit-transform: rotate(-45deg);
                                    margin-left: 0.313rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}