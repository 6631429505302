// $body-bg: #cccccc;
// $body-color: #999999;
// $primary: #000000;

@import './custom_bootstrap';
@import '~/node_modules/bootstrap/scss/bootstrap.scss';
@import '~/node_modules/bootstrap-icons/font/bootstrap-icons.scss';

// Prime React
@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

// custom styles
@import './../fonts/lato-fonts.scss';
@import './variables.scss';
@import './mixins.scss';
@import './../../index.scss';
@import './responsive.scss';
@import './common.scss';