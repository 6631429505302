@keyframes bgrandom {
    0% { background: linear-gradient(90deg, rgba(255,255,255,0.98) 50%, rgba(255,255,255,0.96) 0%); }
    50% { background: linear-gradient(90deg, rgba(255,255,255,0.98) 50%, rgba(255,255,255,0.96) 0%); }

    55% { background: linear-gradient(90deg,  rgba(255,255,255,0.96) 50%, rgba(255,255,255,0.98) 0%); }
    80% { background: linear-gradient(90deg, rgba(255,255,255,0.96)  50%, rgba(255,255,255,0.98) 0%); }

    85% { background: linear-gradient(90deg, rgba(255,255,255,0.96) 50%, rgba(255,255,255,0.94) 0%); }
    100% { background: linear-gradient(90deg, rgba(255,255,255,0.96) 50%, rgba(255,255,255,0.94) 0%); }
}
.avatarImg {

    img {
      width: 36px;
      height: 36px;
    }
  
    .p-avatar {
      align-items: flex-start;
      width: 3rem;
      height: 3rem;
  
      img {
        width: 36px;
        height: 36px;
      }
  
    }
  }
  
 .user-avatar {
    color: white;
    line-height: 30px;
    font-size: .75rem;
    font-weight: 500;
    margin: 0 1px;

    &.avatar1{
      background-color: #B6E0EA;
    }
    &.avatar2{
      background-color: #EE82EE;
    }
    &.avatar3{
      background-color: #5ead4a;
    }
    &.avatar4{
      background-color: #be519a;
    }
    &.avatar5{
      background-color: #cc5a5a;
    }
    &.avatar6{
      background-color: #bdd14c;
    }
    &.avatar7{
      background-color: #4ab8e4;
    }
    &.avatar8{
      background-color: #633f9c;
    }
    &.avatar9{
      background-color: #45c8f0;
    }
    &.avatar0{
      background-color: #f55e84;
    }
  }

  