@import '.././../../../../../assets/styles/styles.scss';

.recomdsummary-wrapper {

    .insights__innerwrapper {
        min-height: 8.125rem;
        margin-bottom: 1rem;

        .mai_growth_icon {
            font-size: 1rem;
        }

        .insights-data {

            h2 {
                margin: 0;
                font-style: normal;
                font-weight: 700;
                font-size: 1.125rem;
                line-height: 1.375rem;
                color: $black;
            }

            h3 {
                margin: 0;
                font-style: normal;
                font-weight: 700;
                font-size: 1.563rem;
                line-height: 2.375rem;
                color: $black;
            }

            h4 {
                margin: 0;
                font-weight: 700;
                font-size: .875rem;
                line-height: .875rem;
                color: $black;
            }

            p {
                margin: 0;
                font-style: normal;
                font-weight: 600;
                font-size: .75rem;
                line-height: .875rem;
                color: $mai-gray-primary-7;
            }

            .innertext__size {

                .blueshade {
                    color: $mai-blueshade4;
                    font-size: 1.563rem;
                }

                .greenshade {
                    color: $mai-green-shade3;
                    font-size: 1.563rem;
                }
            }
        }
    }
}

.stickytopRecomd {
    top: 4.5rem !important;
    background: $white;
}