@import '../../../../../assets/styles/styles.scss';

.recomdsummary-wrapper {

    .insights__innerwrapper {
        min-height: 8.125rem;
        margin-bottom: 1rem;

        .inner__heading {
            font-size: 1.2rem;
        }

        .mai_growth_icon {
            font-size: 1rem;
        }

        .insights-data {
            align-items: flex-start;

            h2 {
                margin: 0;
                font-style: normal;
                font-weight: 700;
                font-size: 1.125rem;
                line-height: 1.375rem;
                color: $black;
            }

            h3 {
                margin: 0;
                font-style: normal;
                font-weight: 700;
                font-size: 1.563rem;
                line-height: 2.375rem;
                color: $black;
            }

            h4 {
                margin: 0;
                font-weight: 700;
                font-size: .875rem;
                line-height: .875rem;
                color: $black;
            }

            p {
                margin: 0;
                font-style: normal;
                font-weight: 600;
                font-size: .75rem;
                line-height: .875rem;
                color: $mai-gray-primary-7;
            }

            .innertext__size {

                .blueshade {
                    color: $mai-blueshade4;
                    font-size: 1.563rem;
                }

                .greenshade {
                    color: $mai-green-shade3;
                    font-size: 1.563rem;
                }
            }
        }
    }
}

.stickytopRecomd {
    top: 3.5rem !important;
    background: $white;
}

.box-shadow-wrapper {
    background: $white;
    border: .063rem solid $mai-white-smoke;
    box-shadow: 0 .25rem .625rem rgba(43, 43, 43, 0.23);
    padding: 1rem;

    @include border-radius(.5rem, .5rem, .5rem, .5rem);

    &.recomd-leftcolmn {
        min-height: 30rem;
    }

    .recomdetails-lefttop {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        h4 {
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.375rem;
            color: $black;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                font-weight: 400;
                font-size: .875rem;
                line-height: .875rem;
                color: $mai-cool-gray;
                margin-bottom: .625rem;

                &:first-child {

                    i {
                        color: $mai-blueshade4;
                        font-size: .625rem;
                        margin-right: .313rem;
                    }
                }

                &:last-child {

                    i {
                        color: $mai-greenshade6;
                        font-size: .625rem;
                        margin-right: .313rem;
                    }
                }
            }
        }
    }

    .recom-prodlist {
        margin: 1.25rem 0 0;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                border: .063rem solid $mai-white-smoke;
                background: $white;
                min-height: 3.25rem;
                padding: .625rem .938rem;
                line-height: .875rem;
                margin-bottom: .75rem;
                position: relative;

                @include border-radius();

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: .875rem;
                    line-height: 1.063rem;
                    color: $black;
                    display: block;
                }

                &.active {
                    background: $mai-aliceblue-shade;

                    &::after {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: .25rem;
                        height: 100%;
                        background: $mai-blue-primary-4 ;
                        @include border-radius();
                        content: '';
                    }
                }
            }
        }
    }
}

.recomd-cacconversion {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: .625rem;

    .recomd-innerwrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 .938rem;
        min-height: 3.75rem;

        img {
            margin-right: 1.063rem;
        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 1.5rem;
            color: $mai-eerie-black;
            margin: 0;
        }

        p {
            color: #8993a3;
            font-size: .875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1rem;
            margin: 0.5rem 0 0;
        }
    }

    .recomd-left {
        width: 50%;
        border-right: .063rem solid $mai-blueshade6;
    }

    .recomd-right {
        width: 50%;
    }
}

.recomdinsights {

    .tabs-wrapper {

        .p-tabview-nav-container {
            margin-right: 0 !important;
        }
    }
}

.increase_horizontal {
    background: $mai-green-shade2;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 0.938rem;
    color: $mai-green-shade3;
    display: inline-block;
    padding: 0.063rem .188rem;
    margin-left: 0.438rem;

    @include border-radius(.25rem, .25rem, .25rem, .25rem);
}

.recomd-filter {

    .modal-body {

        h2 {
            font-size: 1rem;
            font-weight: 700;
            color: $black;
        }

        .mai-input {
            background: $mai-gray-primary-3;
            border: .063rem solid rgba(19, 34, 149, 0.1);
            @include border-radius();

            &:focus {
                box-shadow: none;
            }
        }
    }
}

.saveasaudiencePopup {

    .modal-dialog {
        max-width: 108.375rem;
        @include border-radius();

        .modal-body {

            .listof-kpi {

                .form-check {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;

                    input {
                        background-color: $white;
                        border: .063rem solid $mai-blueshade;

                        @include border-radius(.125rem, .125rem, .125rem, .125rem);
                    }

                    label {

                        &.form-check-label {
                            margin: .125rem 0 0 .938rem;
                            line-height: 1.063rem;
                            font-weight: 500;
                        }
                    }
                }
            }

        }
    }

}

.customerprofilepop-wrap {

    .modal-dialog {
        max-width: 59.375rem;

        .modal-header {
            padding-bottom: 0;

            @include modalHeader;

            h1 {
                @include modalTitle;
            }
        }

        .modal-body {

            .customerprofilepic {
                background: $mai-light-gray;
                border: .063rem solid $mai-white-shade1;
                padding: .5rem .938rem;

                @include border-radius();

                h3 {
                    font-weight: 700;
                    font-size: 1.25rem;
                    line-height: 1.5rem;
                    color: $black;
                    margin: .625rem 0 .313rem;
                }

                p {
                    font-weight: 400;
                    font-size: .875rem;
                    line-height: 1.063rem;
                    color: $mai-cool-gray;
                    margin: 0;
                }
            }

            .customerprofiledesc {

                .custprofileblock {
                    background: $mai-light-gray;
                    border: .063rem solid $mai-white-shade1;
                    @include border-radius();
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: .625rem .938rem;

                    .profiletitle {
                        margin: 0 .625rem 0 1rem;

                        &.profiletitlewidth {
                            width: 35%;
                        }

                        h4 {
                            font-weight: 700;
                            font-size: 1.063rem;
                            line-height: 1.375rem;
                            color: $mai-eerie-black;
                            margin: 0;
                        }

                        p {
                            &.subtitle {
                                font-weight: 400;
                                font-size: .875rem;
                                line-height: 1.063rem;
                                color: $mai-cool-gray;
                                margin: 0;
                            }
                        }
                    }

                    .profilesname {

                        span {
                            width: 2rem;
                            height: 2rem;
                            display: inline-block;
                            margin-right: .938rem;
                            font-weight: 700;
                            font-size: .75rem;
                            line-height: .875rem;
                            color: $mai-dark-shade;
                            text-align: center;
                            padding: .563rem 0;

                            @include border-radius(50%, 50%, 50%, 50%);

                            &.color1 {
                                background: $mai-blueshade5;
                            }

                            &.color2 {
                                background: $mai-yellowshade1;
                            }
                        }

                        .totalrevenuenumber {
                            margin: 0;
                            font-weight: 700;
                            font-size: 1.5rem;
                            line-height: 1.813rem;
                            color: $mai-blue-primary-1;

                            p {
                                margin: 0;
                            }
                        }
                    }

                }
            }

            .inner__heading {

                span {
                    font-size: 1.125rem;
                }
            }
        }

        .modal-footer {

            @include modalFooter;

        }


    }
}


.custprofilebox {
    background: $mai-light-gray;
    border: .063rem solid $mai-white-shade1;
    @include border-radius();
    min-height: 16.75rem;

    .custprofile-chartindicator {

        ul {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: .938rem .938rem 0;

            li {
                width: 48%;
                margin: 0 0 .938rem;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.188rem;
                color: $black;
            }
        }
    }
}