@import '../../assets/styles/styles.scss';

.mai-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    background: $white;
    padding: 0 1.5rem;

    .header__menu {
        width: 50%;

        ul {
            margin: 0;

            li {

                a {
                    color: $black;
                    font-size: 1.5rem;
                    line-height: 1.813rem;
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }
    }

    .header__profile {
        width: 50%;

        ul {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            li {
                margin: 0;
                border-right: 0.063rem solid $mai-gray-primary-4;
                padding: 0 0.75rem;
                line-height: 1.8rem;

                &:hover {
                    cursor: pointer;
                }

                &:first-child {
                    border-left: none;
                }

                &:last-child {
                    border: none;
                    padding-right: 0;

                    .header__profile-sec {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .header__profile-shortname {
                        background: $mai-dark-purple-shade;
                        width: 2rem;
                        height: 2rem;
                        color: $white;
                        text-align: center;
                        line-height: 2rem;
                        border-radius: 50%;
                        font-size: 0.875rem;
                        font-weight: 400;
                    }

                    .header__profile-title {
                        margin: 0 0.938rem 0 0.5rem;
                    }

                }
            }
        }
    }
}

.header__sidebar {

    .p-sidebar-header {
        padding: 1.25rem 1.25rem 0;
        margin-bottom: -1.875rem;
    }

    .p-sidebar-close {

        &:focus {
            box-shadow: none !important;
        }
    }

    .p-sidebar-close-icon {
        color: $mai-eerie-black;
        font-size: 0.813rem;
    }

    .header__notification {
        margin: 0 0 .938rem;
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.813rem;
        color: $black;
    }

    .header__notifications-desc {

        .header__notifications-para {
            background-color: $mai-gray-shade;
            margin: 0 0 .5rem;
            font-weight: 400;
            font-size: .813rem;
            line-height: 1.125rem;
            padding: .75rem;
            color: $black;
            border-radius: .25rem;

            .header__notification-namedate {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                .notification__shortname {
                    background: $mai-vivid-blue;
                    border-radius: 1.313rem;
                    width: 1.875rem;
                    height: 1.875rem;
                    color: $white;
                    font-size: 0.75rem;
                    font-weight: 400;
                    display: block;
                    text-align: center;
                    padding: 0.313rem 0;
                    line-height: 1.25rem;
                }

                .notification__fullname {
                    margin: 0;
                    font-weight: 700;
                    color: $black;
                    font-size: 0.813rem;
                    line-height: 1rem;
                }

                .notification__date {
                    font-weight: 600;
                    font-size: 0.75rem;
                    line-height: 0.875rem;
                    color: $black;
                    opacity: 0.4;
                }

                .shortname__wrap {
                    width: 15%;
                }

                .fullname__wrap {
                    width: 85%;
                }

                .shortname__color1 {
                    background: $mai-red-shade;
                }

                .shortname__color2 {
                    background: $mai-green-shade;
                }

                .shortname__color3 {
                    background: $mai-yellow-shade;
                }
            }

            .notification__desc {
                color: $black;
                font-size: 0.813rem;
                line-height: 0.938rem;
                opacity: 0.9;
                margin-top: 0.313rem;
                font-weight: 500;
            }
        }

        .header__new-notifications {
            background-color: $mai-light-orange;
        }
    }
}

.header__logout {
    border: none !important;
    box-shadow: 0 0.188rem 0.188rem $mai-light-silver;
    padding: 0 !important;
    background-color: $mai-gray-primary-5 !important;    
    @include border-radius();

    .dropdown-item {

        &:hover {
            @include border-radius();
        }  
        
        &:active{
            background-color: $mai-gray-primary-5 !important; 
            @include border-radius();
            color: $black;
        }

        &:focus-visible{
            outline: none;
        }

    }

    a {
        color: $mai-dark-purple-shade;
        font-weight: 500;
        background: $white;
        padding: 0.5rem 0.938rem;
        line-height: 1.25rem;
        border-radius: 0 0 0.438rem 0.438rem;
        font-size: 0.938rem;

        &.active {
            color: $mai-dark-purple-shade;
            background-color: $white;
        }

        span {
            font-weight: 600 !important;
            font-size: 0.938rem !important;
        }
    }
}