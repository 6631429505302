@import '../../../../../../assets/styles/styles.scss';

.industry-prod-datatable {
    .p-datatable-wrapper {
        max-height: 18.5rem !important;
    }

    .p-datatable-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 .063rem $white;
        @include border-radius(0, 0, 0, 0);
        background-color: transparent;
    }

    .p-datatable-wrapper::-webkit-scrollbar {
        width: .35rem;
        background-color: transparent;
        height: .32rem;
    }

    .p-datatable-wrapper::-webkit-scrollbar-thumb {
        @include border-radius();
        background: $mai-gray-shade6;
        min-height: 6.25rem;
    }
}

.segment-personas-tabs {
    .p-tabview {
        .p-tabview-panels {
            float: left;
            width: 100%;

            .p-tabview-panel {
                padding: 0 !important;
            }
        }
    }
}

.insights__innerwrapper {
    .segment-personas-tabs {
        .p-tabview-nav-container {
            .p-tabview-nav-content {
                .p-tabview-nav {
                    margin: 0;

                    li {
                        &.p-unselectable-text {
                            background-color: #fff;
                            border-right: none;
                            padding: 0 5px 0 0;
                            margin-right: 5px;

                            .p-tabview-nav-link {
                                padding: 0.5rem 0 .8rem;
                            }
                        }

                        &.p-tabview-ink-bar {
                            display: block;
                            margin: 0;
                        }
                    }
                }
            }

        }

        .p-datatable-wrapper {
            .p-datatable-table {

                thead {
                    th {
                        .pi-sort-alt {
                            position: relative;

                            &::before {
                                position: absolute;
                                content: '';
                                width: .57rem;
                                height: .75rem;
                                top: 0;
                                left: 0;
                                background: url('../../../../../../assets/images/shorting-icon.svg') no-repeat scroll center center;
                                background-size: cover;
                                opacity: 0.4;
                            }
                        }

                        .pi-sort-amount-up-alt {
                            position: relative;

                            &::before {
                                position: absolute;
                                opacity: 0.4;
                                top: 0;
                                left: 0;
                            }
                        }

                        .pi-sort-amount-down {
                            position: relative;

                            &::before {
                                position: absolute;
                                opacity: 0.4;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        border-top: .07rem solid $mai-gray-shade;
                        margin-bottom: .32rem;
                        padding-top: .32rem;

                        &:last-child {
                            border-bottom: none;
                        }

                        td {
                            &:first-child {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }


    }
}