@import '../../assets/styles/styles.scss';

.mai-welcome {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;

    .welcome__navbar {
        min-width: 22%;
        width: 22%;
        position: fixed;
        left: 0;
        top: 0;
        transition: all 0.5s;
        height: 100vh;
    }

    .welcome__header-body {
        width: calc(100% - 22%);
        margin-left: 22%;
        transition: all 0.5s;
    }
}

.my-container {
    transition: all 0.5s;
}