@import '../../../../../../assets/styles/styles.scss';

.distribution-indicator {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left: 1rem;

    .distribution-indicator-inner {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        margin-bottom: 1.25rem;

        &:last-child {
            margin-bottom: 0;
        }

        h4 {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.13rem;
            color: $black;
        }

        p {
            font-weight: 400;
            font-size: .88rem;
            line-height: 1.38rem;
            color: $mai-cool-gray;
        }

    }
}

.channellist {
    margin: 0;
    padding: 0;

    li {
        margin-right: 1rem;
        display: inline-block;
        font-size: 1rem;
        font-weight: 600;
        color: $black;

        &:last-child {
            margin-right: 0;
        }
    }
}

.indicator {
    width: .5rem;
    height: .5rem;
    @include border-radius(50%, 50%, 50%, 50%);
    display: inline-block;
    margin-right: .32rem;
}

.solidgreen-circle {
    background: $mai-green-shade1;
}

.solidblue-circle {
    background: $mai-blue-shade;
}

.solidred-circle {
    background: $mai-red-shade2;
}

.channelroi-list {
    &.p-dropdown {
        background: $mai-gray-primary-3;
        border: .06rem solid rgba(19, 34, 149, 0.1);
        @include border-radius(.25rem, .25rem, .25rem, .25rem);
        margin: 0;
        padding: .32rem;
        min-width: 7.19rem;

        .p-dropdown-label {
            color: $black;
        }

        .p-dropdown-trigger {
            width: 1rem;
        }

        &:not(.p-disabled) {
            &:hover {
                border-color: rgba(19, 34, 149, 0.1);
            }
        }

        &:not(.p-disabled) {
            &.p-focus {
                border-color: rgba(19, 34, 149, 0.1);
            }
        }
    }
}

.channelroi-list-pannel {
    margin-left: .38rem;
}

.roilist {
    .roilist-inner {
        background: $mai-light-gray;
        border: .06rem solid $mai-blue-primary-4;
        @include border-radius();
        padding: .88rem;
        margin-bottom: 1.57rem;

        &:last-child {
            margin-bottom: 0;
        }

        h4 {
            font-size: 1rem;
            font-weight: 700;
            color: $black;
            margin-bottom: .5rem;
        }

        h5 {
            font-size: 1.25rem;
            font-weight: 700;
            color: $black;
        }
    }
}

.p-datatable {
    .metricsdatatable {
        .p-datatable-thead>tr>th {
            font-size: 1rem;
            font-weight: 600;
            color: $mai-cool-gray;
            padding: 0.5rem .5rem;
            border: none;
            background: $white;
        }

        .p-datatable-tbody>tr>td {
            font-size: .88rem;
            font-weight: 600;
            color: $black;
            padding: 1rem .5rem;
            border-color: $mai-gray-shade;
            width: 25%;
            line-height: 1.13rem;

            &:first-child {
                color: $mai-cool-gray;
                font-weight: 500;
                font-size: 1rem;
            }
        }
    }
}