@import '../../../assets/styles/styles.scss';

%module-heading {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.938rem;
    margin: 1.563rem 0 0.938rem;
    color: $black;
}

%column-box {
    background-color: $white;
    border-radius: .5rem;
    box-shadow: 0 .25rem .5rem $mai-light-silver;
}

%heading-title {
    font-weight: 600;
    line-height: 1.563rem;
    color: $black;
    margin: 0.438rem 0;
}

.mai-home__body {
    width: 100%;
    background: $mai-gray-primary-5;
    padding: 1.875rem 1.8rem;

    .home__title {

        .home__name {
            h1 {
                font-weight: 500;
                font-size: 2.5rem;
                line-height: 2.5rem;
                margin: 0;
                color: $black;
            }
        }

        .home__create {

            .home__create-anchor {
                background: $mai-blue-primary-1;
                height: 2.5rem;
                display: inline-block;
                padding: 0 1.625rem;
                color: $white;
                text-decoration: none;
                line-height: 2.5rem;
                font-size: 0.875rem;
                font-weight: 400;
                border-radius: 0.25rem;

                .home__plus-icon {
                    margin-right: 0.313rem;
                }
            }

            .home__create-dropdown {
                border: none;
                box-shadow: 0.125rem 0.313rem 0.188rem $mai-light-silver;
                padding: 0.938rem;

                li {
                    margin-bottom: 0.625rem;                    

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .home__create-dropdownitem {
                        padding: 0;
                        font-size: 1rem;
                        color: $black;
                        background-color: transparent;

                        &:hover {
                            background-color: transparent;
                        }

                        .home__plus {
                            font-size: 0.563rem;
                            color: $mai-blue-primary-4;
                            margin-right: 0.313rem;
                            font-weight: 700;
                        }
                    }

                }
            }
        }
    }

    .home__modules {

        .home__modules-heading {
            @extend %module-heading;
        }

    }

    .home__module-inner {       

        .home__module-cols {
            @extend %column-box;
            padding: 0.938rem;

            .home__modules-title {
                font-size: 1.313rem;
                @extend %heading-title;
            }

            .home__modules-desc {
                font-style: normal;
                font-weight: 400;
                font-size: 0.875rem;
                line-height: 1.063rem;
                color: $mai-gray-primary-7;
                margin-bottom: 1rem;
            }

            .home__modules-knowmore {
                line-height: 0.938rem;

                a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.063rem;
                    color: $mai-blue-primary-4;
                    text-decoration: none;

                    img {
                        margin-left: 0.625rem;
                    }
                }

            }

        }
    }

    .home__howitworks {

        .home__modules-heading {
            @extend %module-heading;
        }
    }

    .home__howitworks-inner {        

        .home__howitworks-cols {
            @extend %column-box;
            padding: 0.625rem;
            margin-bottom: 0.625rem;

            .home__howitworks-img {
                img {
                    width: 100%;
                }
            }

            .home__howitworks-title {
                font-size: 0.8rem;
                line-height: 1.188rem;
                @extend %heading-title;

            }

            .home__howitworks-desc {
                font-style: normal;
                font-weight: 400;
                font-size: 0.75rem;
                line-height: 0.875rem;
                color: $mai-gray-primary-8;
                margin: 0;
            }
        }
    }
}