@import '../../../../../assets/styles/styles.scss';


.segmentationcreate__form-wrapper {
    background-color: $mai-alice-blue;
    padding: 1rem;

    .segmentationcreate__form {
        background: $white;
        border: 0.063rem solid $mai-white-smoke ;
        padding: 1rem;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);

        .titles {
            font-style: normal;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 1.813rem;
            color: $black;
        }
    }
}

.segmentationlist {

    .segmentationlist__select {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        label {
            width: 5.813rem;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 1.188rem;
            color: $mai-gray-primary-7;
            text-align: right;
        }

        select {
            width: 3.125rem;
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.313rem;
            color: $mai-blue-primary-1;
            border: none;
            padding-left: .625rem;
            padding-right: .938rem;
            background-position: right;

            &:focus {
                box-shadow: none;
            }

        }
    }

    .welcome__create-anchor {
        background: $mai-blue-primary-1;
        height: 2.5rem;
        display: inline-block;
        padding: 0 1rem;
        color: $white;
        text-decoration: none;
        line-height: 2.5rem;
        font-size: 0.875rem;
        font-weight: 400;

        @include border-radius(.25rem, .25rem, .25rem, .25rem);

        .welcome__plus-icon {
            margin-right: 0.313rem;
        }
    }
}

.segmentationlist-datatable {

    .p-datatable-wrapper {
        max-height: 27.8rem !important;
        min-height: 27.8rem;

        .p-datatable-table {

            .p-datatable-emptymessage {
                td {
                    max-width: 100% !important;
                    justify-content: flex-start !important;
                }
            }
        }
    }

    .p-datatable-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 .063rem $white;
        @include border-radius(0, 0, 0, 0);
        background-color: transparent;
    }

    .p-datatable-wrapper::-webkit-scrollbar {
        width: .35rem;
        background-color: transparent;
        height: .32rem;
    }

    .p-datatable-wrapper::-webkit-scrollbar-thumb {
        @include border-radius();
        background: $mai-gray-shade6;
        min-height: 6.25rem;
    }
}

.customer-badge {
    padding: 0.25em 0.5rem;
    font-weight: 700;
    font-size: .75rem;
    letter-spacing: .019rem;

    @include border-radius(.125rem, .125rem, .125rem, .125rem);
}

.customer-badge.status-published {
    background: rgb(28 154 78 / 10%);
    font-weight: 400;
    font-size: .75rem;
    line-height: .875rem;
    color: $mai-green-shade3;
    min-width: 4.375rem;

    @include border-radius(.25rem, .25rem, .25rem, .25rem);
}

.customer-badge.status-draft {
    background: rgb(230 152 100 / 10%);
    font-weight: 400;
    font-size: .75rem;
    line-height: .875rem;
    text-align: center;
    color: $mai-orangeshade;
    min-width: 4.375rem;

    @include border-radius(.25rem, .25rem, .25rem, .25rem);
}

.whiteboxlist-height {
    height: calc(100vh - 5.5rem);
}